/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Button, Cell, DateRange, Grid, Heading, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import useFirebase from 'components/firebase/useFirebase'
import {
  CheckboxField,
  EquipeSelectField,
  EquipeSelectModel,
  Form,
  FormDebouncedValueSpy,
  FormRenderProps,
  SubmitButton,
  SwitchField,
} from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import createDecorator from 'final-form-calculate'
import {
  OrdemListaAtendimentoEnum,
  StatusAtendimento,
  TipoEstabelecimentoEnum,
  TipoServico,
} from 'graphql/types.generated'
import { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { statusAtendimentoStyles } from '../listaAtendimentoStyles'

export interface ListaAtendimentoFilterPopperModel {
  statusAtendimento?: StatusAtendimento[]
  periodo: DateRange
  tiposServico?: TipoServico[]
  responsaveis?: LotacaoAndEstagioSelectFieldModel[]
  equipes?: EquipeSelectModel[]
  ordem?: OrdemListaAtendimentoEnum
  somenteNaoFinalizados?: boolean
}

const path = metaPath<ListaAtendimentoFilterPopperModel>()

const filterFormDecorator = (defaultPeriodo: DateRange) => {
  return (
    defaultPeriodo &&
    createDecorator({
      field: path.somenteNaoFinalizados.absolutePath(),
      updates: {
        [path.statusAtendimento.absolutePath()]: (value: boolean, allValues: ListaAtendimentoFilterPopperModel) =>
          value
            ? allValues.statusAtendimento &&
              allValues.statusAtendimento.length > 0 &&
              allValues.statusAtendimento.filter(
                (item) => item !== StatusAtendimento.ATENDIMENTO_REALIZADO && item !== StatusAtendimento.NAO_AGUARDOU
              )
            : allValues.statusAtendimento,
        [path.periodo.absolutePath()]: (
          value: boolean,
          allValues: ListaAtendimentoFilterPopperModel,
          prevValues: ListaAtendimentoFilterPopperModel
        ) => {
          return value
            ? { startDate: undefined, endDate: undefined }
            : prevValues.somenteNaoFinalizados
            ? defaultPeriodo
            : allValues.periodo
        },
      },
    })
  )
}

export interface ListaAtendimentoPopperProps {
  defaultPeriodo: DateRange
  initialValues?: ListaAtendimentoFilterPopperModel
  isFilterEqual?: boolean
  onFechar(): void
  onSubmit(values: ListaAtendimentoFilterPopperModel): void
  onClear(): void
  onChangeFilter(values: ListaAtendimentoFilterPopperModel): void
}

export function ListaAtendimentoFilterPopperForm(props: ListaAtendimentoPopperProps) {
  const { defaultPeriodo, initialValues, isFilterEqual, onSubmit, onFechar, onClear, onChangeFilter } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const decorator = useMemo(() => [filterFormDecorator(defaultPeriodo)], [defaultPeriodo])
  const unidadeSaude = acesso?.unidadeSaude
  const isCEO = unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const { analytics } = useFirebase()

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_popper_LA')
  }

  const renderForm = (formProps: FormRenderProps<ListaAtendimentoFilterPopperModel>) => {
    const isSomenteNaoFinalizados = formProps.values.somenteNaoFinalizados

    return (
      <Box
        style={css`
          width: 42rem;
          margin-top: 1rem;
          margin-right: -0.1875rem;
        `}
      >
        <FormDebouncedValueSpy onChange={(formState) => onChangeFilter(formState.values)} wait={0} />

        <Grid gapVertical={1} gap={1} justifyContent='flex-end'>
          <Cell size={12}>
            <Grid gapVertical={0.5}>
              <Cell size={12}>
                <Heading level={5}>Status do atendimento</Heading>
              </Cell>
              <Cell size={12} style={{ marginLeft: '-0.25rem' }}>
                <Grid gap={0} gapVertical={0.5}>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender(
                        'Aguardando atendimento',
                        statusAtendimentoStyles.aguardandoAtendimentoBox
                      )}
                      value={StatusAtendimento.AGUARDANDO_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Em atendimento', statusAtendimentoStyles.emAtendimentoBox)}
                      value={StatusAtendimento.EM_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Em escuta inicial', statusAtendimentoStyles.emEscutaInicialBox)}
                      value={StatusAtendimento.EM_ESCUTA_INICIAL}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender(
                        'Atendimento realizado',
                        statusAtendimentoStyles.atendimentoRealizadoBox
                      )}
                      value={StatusAtendimento.ATENDIMENTO_REALIZADO}
                      disabled={isSomenteNaoFinalizados}
                    />
                  </Cell>
                  <Cell size={4}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Não aguardou', statusAtendimentoStyles.naoAguardouBox)}
                      value={StatusAtendimento.NAO_AGUARDOU}
                      disabled={isSomenteNaoFinalizados}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
          <Cell size={12}>
            <SwitchField name={path.somenteNaoFinalizados} label='Ver somente os atendimentos não finalizados' />
          </Cell>
          <Cell>
            <Grid gapVertical={1}>
              <Cell size={6}>
                <HFlow alignItems='flex-end'>
                  <DateRangeField
                    name={path.periodo}
                    label='Período'
                    maxDate={defaultPeriodo.endDate}
                    disabled={isSomenteNaoFinalizados}
                  />
                </HFlow>
              </Cell>
              <Cell size={6}>
                <TipoServicoSelectField label='Tipo serviço' name={path.tiposServico} multiple includeInterno={true} />
              </Cell>
              {!isCEO && (
                <Cell size={6}>
                  <EquipeSelectField label='Equipe' name={path.equipes} unidadeSaudeId={unidadeSaude.id} multiple />
                </Cell>
              )}
              <Cell size={isCEO ? 12 : 6}>
                <LotacaoAndEstagioSelectField
                  label='Profissional'
                  name={path.responsaveis}
                  unidadeSaudeId={unidadeSaude.id}
                  multiple
                  includeEstagios
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell alignSelf='flex-end'>
            <Button onClick={onFechar} size='small' skin='outline' kind='normal'>
              Fechar
            </Button>
          </Cell>
          <Cell alignSelf='flex-end'>
            <Tooltip text='Redefinir filtros para o padrão'>
              <Button kind='normal' size='small' onClick={handleOnClear} disabled={isFilterEqual}>
                <Icon style={{ paddingRight: '0.5rem' }} icon='redo' />
                Voltar para padrão
              </Button>
            </Tooltip>
          </Cell>
          <Cell alignSelf='flex-end'>
            <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
              Filtrar
            </SubmitButton>
          </Cell>
        </Grid>
      </Box>
    )
  }
  return (
    <Form<ListaAtendimentoFilterPopperModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onSubmitSucceeded={onFechar}
      decorators={decorator}
    />
  )
}

const filterLabelRender = (nome: string, cssStyles: SerializedStyles) => (
  <HFlow hSpacing={0} justifyContent='center' alignItems='center'>
    <div
      css={css(
        cssStyles.styles,
        css`
          width: 0.5rem;
          height: 1.5rem;
          border-radius: 0.125rem;
          margin-right: 0.5rem;
        `
      )}
    />
    {nome}
  </HFlow>
)
