import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { EquipeSelectField, EquipeSelectModel, Form, FormRenderProps, SubmitButton } from 'components/form'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useApolloClient } from 'graphql/hooks'
import { EquipeAtendimentoDocument, useAtendimentoQuery, useEditarAtendimentoMutation } from 'graphql/hooks.generated'
import { CidadaoBasico, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { metaPath } from 'util/metaPath'
import { TipoServicoCheckField } from 'view/configuracoes/municipal/tipo-servico/TipoServicoCheckField'

import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from '../../../../../components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'

interface UrlParams {
  atendimentoId: string
}

interface EditarAtendimentoModalFormModel {
  atendimentoId: string
  cidadao: CidadaoBasico
  equipe?: EquipeSelectModel
  lotacao?: LotacaoAndEstagioSelectFieldModel
  tiposServico?: string[]
}

const path = metaPath<EditarAtendimentoModalFormModel>()

const equipeCalculator = (apollo, isCEO?: boolean) =>
  createDecorator({
    field: path.lotacao.absolutePath(),
    updates: {
      [path.equipe.absolutePath()]: (lotacao: LotacaoAndEstagioSelectFieldModel) => {
        if (lotacao?.equipe?.id && !isCEO) {
          return apollo
            .query({
              query: EquipeAtendimentoDocument,
              variables: { id: lotacao.equipe.id },
            })
            .then((res) => res.data.equipe)
        } else {
          return null
        }
      },
    },
  })

export const EditarAtendimentoModalForm = () => {
  const apollo = useApolloClient()
  const [editarAtendimento] = useEditarAtendimentoMutation()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const handleRejection = useErrorHandler()
  const history = useHistory()
  const unidadeSaude = acesso?.unidadeSaude
  const isCEO = unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const { analytics } = useFirebase()

  const { atendimentoId } = useParams<UrlParams>()
  const { data } = useAtendimentoQuery({ variables: { id: atendimentoId } })

  const equipeCalculatorMemo = useMemo(() => equipeCalculator(apollo), [apollo])

  if (!data?.atendimento) {
    return null
  }

  const viewData: EditarAtendimentoModalFormModel = {
    ...data.atendimento,
    atendimentoId: data.atendimento.id,
    lotacao: data.atendimento.responsavel,
    equipe: data.atendimento.equipe,
    tiposServico: data.atendimento.tiposServico?.map((tipo) => tipo.id),
  }

  const goBack = () => {
    return history.replace('/lista-atendimento/', '')
  }

  const handleSubmit = (values: EditarAtendimentoModalFormModel, formApi: FormApi) => {
    editarAtendimento({
      variables: {
        input: {
          atendimento: values.atendimentoId,
          equipe: values.equipe?.id,
          responsavel: values.lotacao?.id,
          tiposServico: values.tiposServico,
        },
      },
    })
      .then((res) => {
        alert('success', `Cidadão foi alterado com sucesso. `)
        analytics.logEvent('editar_cidadao_LA')
        setTimeout(formApi.reset)
        return history.goBack()
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps<EditarAtendimentoModalFormModel>) => {
    const values: EditarAtendimentoModalFormModel = formProps.values

    return (
      <Modal open={true} onClose={goBack} size='auto' closeOnBackdropClick={false} style={{ width: '45rem' }}>
        <ModalBody>
          <Grid alignItems='flex-start'>
            <Cell size={12}>
              <Heading level={1}>Editar item na lista de atendimentos</Heading>
            </Cell>
            <Cell size={12}>
              <VFlow vSpacing={0}>
                <b>Cidadão</b>
                <Text>{values.cidadao.nomeSocial || values.cidadao.nome}</Text>
              </VFlow>
            </Cell>
            <Cell size={12}>
              <LotacaoAndEstagioSelectField
                name={path.lotacao}
                label='Profissional'
                unidadeSaudeId={unidadeSaude.id}
                disabled={values.equipe && !values.lotacao}
                includeEstagios
              />
            </Cell>
            {!isCEO && (
              <Cell size={12}>
                <EquipeSelectField
                  name={path.equipe}
                  label='Equipe'
                  unidadeSaudeId={unidadeSaude.id}
                  disabled={!!values.lotacao}
                />
              </Cell>
            )}
            <Cell size={12}>
              <TipoServicoCheckField name={path.tiposServico} />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack}>Cancelar</Button>
            <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<EditarAtendimentoModalFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      decorators={[equipeCalculatorMemo]}
      initialValues={viewData}
    />
  )
}
