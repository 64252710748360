import { resolveValue } from 'components/form/final-form/hooks/useField'
import { Calculation } from 'final-form-calculate'
import { SexoEnum, TipoAtendimentoEnum, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { inRange, isEmpty } from 'lodash'
import { ProcedimentosAutomaticosPreNatal, ProcedimentosAutomaticosPuerperio } from 'types/enums'
import { monthsToYearsFloor } from 'util/date/calculateAge'
import { Meta, MetaArray, MetaRoot } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { ProcedimentoSigtapFieldModel } from 'view/atendimentos/detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'
import { calculateProcedimentoAutomaticoIndividual } from 'view/atendimentos/utils/procedimentoAutomaticoUtils'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import {
  hasProblemaCondicaoDePreNatal,
  hasProblemaNaoEvolucaoPuerperio,
} from '../../avaliacao/components/problemas-condicoes/utils/verifications'
import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'

const createTipoAtendimentoCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  isMedico: boolean,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  isGestante: boolean,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  POLICLINICA_ENABLED: Boolean
): Calculation => ({
  field: [meta.tipoAtendimento.absolutePath(), metaProblemasCondicoes.absolutePath()],
  updates: {
    [meta.procedimentosAdministrativos.absolutePath()]: (
      _: TipoAtendimentoEnum,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      if (!isEmpty(prevValues)) {
        const problemasCondicoes = resolveValue<ProblemaCondicaoModel[]>(allValues, metaProblemasCondicoes) || []

        const procedimentoAutomaticoPuerperio = findProcedimentoAutomaticoByCodigo(
          procedimentosAutomaticos,
          ProcedimentosAutomaticosPuerperio.CONSULTA_PUERPERAL
        )

        if (
          hasProblemaNaoEvolucaoPuerperio(problemasCondicoes) &&
          canAddProcedimentoAutomaticoGestacao(
            procedimentoAutomaticoPuerperio,
            hasPermissionPreNatal,
            idadeCidadaoEmAnos,
            desejaInformarIdentidadeGenero,
            sexo
          )
        ) {
          return getProcedimentosWithNewProced(
            allValues.finalizacao.procedimentosAdministrativos,
            procedimentoAutomaticoPuerperio
          )
        }

        const procedimentoAutomaticoPreNatal = findProcedimentoAutomaticoByCodigo(
          procedimentosAutomaticos,
          ProcedimentosAutomaticosPreNatal.CONSULTA_PRE_NATAL
        )

        const deveAdicionarProcedimentoAutomaticoPreNatal =
          hasProblemaCondicaoDePreNatal(problemasCondicoes, isGestante) &&
          canAddProcedimentoAutomaticoGestacao(
            procedimentoAutomaticoPreNatal,
            hasPermissionPreNatal,
            idadeCidadaoEmAnos,
            desejaInformarIdentidadeGenero,
            sexo
          )

        if (deveAdicionarProcedimentoAutomaticoPreNatal) {
          return getProcedimentosWithNewProced(
            allValues.finalizacao.procedimentosAdministrativos,
            procedimentoAutomaticoPreNatal
          )
        }

        return calculateProcedimentosAutomaticosByTipoAtendimento(
          allValues,
          procedimentosAutomaticos,
          tipoEstabelecimento,
          isMedico,
          POLICLINICA_ENABLED
        )
      }

      return allValues.finalizacao.procedimentosAdministrativos
    },
  },
})

const canAddProcedimentoAutomaticoGestacao = (
  procedimentoGestacao: ProcedimentoAutomatico,
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexoCidadao: SexoEnum
): boolean => {
  if (hasPermissionPreNatal) {
    const { idadeMinima, idadeMaxima, sexo: sexoProcedimento } = procedimentoGestacao
    const idadeMinimaEmAnos = monthsToYearsFloor(idadeMinima)
    const idadeMaximaEmAnos = monthsToYearsFloor(idadeMaxima)
    return (
      inRange(idadeCidadaoEmAnos, idadeMinimaEmAnos, idadeMaximaEmAnos) &&
      ((!desejaInformarIdentidadeGenero && sexoProcedimento === sexoCidadao) || desejaInformarIdentidadeGenero)
    )
  } else {
    return false
  }
}

const calculateProcedimentosAutomaticosByTipoAtendimento = (
  allValues: SoapState,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  isMedico: boolean,
  POLICLINICA_ENABLED: Boolean
): ProcedimentoSigtapFieldModel[] => {
  const procedToAdd = calculateProcedimentoAutomaticoIndividual(
    tipoEstabelecimento,
    allValues.finalizacao.tipoAtendimento,
    isMedico,
    POLICLINICA_ENABLED
  )

  return getProcedimentosWithNewProced(
    allValues.finalizacao.procedimentosAdministrativos,
    findProcedimentoAutomaticoByCodigo(procedimentosAutomaticos, procedToAdd)
  )
}

const findProcedimentoAutomaticoByCodigo = (
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  codigoProcedimento: string
) => procedimentosAutomaticos.find((procedimentoAutomatico) => procedimentoAutomatico.codigo === codigoProcedimento)

export const getProcedimentosWithNewProced = (
  proceds: ProcedimentoSigtapFieldModel[],
  newProced: ProcedimentoAutomatico
) => {
  let listProcedAdministrativos = proceds || []

  if (!newProced) {
    return listProcedAdministrativos.filter((proced) => !proced.isAutomatico)
  }
  const containsProced = listProcedAdministrativos.find((proced) => proced.procedimento.codigo === newProced.codigo)
  if (!containsProced) {
    return listProcedAdministrativos
      .filter((proced) => !proced.isAutomatico)
      .concat({
        _id: uuidv4(),
        procedimento: newProced,
        isAutomatico: true,
      })
  }

  return listProcedAdministrativos
}

export const createFinalizacaoIndividualCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  isMedico: boolean,
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  isGestante: boolean,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  POLICLINICA_ENABLED: Boolean
): Calculation[] => [
  createTipoAtendimentoCalculations(
    meta,
    metaProblemasCondicoes,
    isMedico,
    procedimentosAutomaticos,
    hasPermissionPreNatal,
    idadeCidadaoEmAnos,
    desejaInformarIdentidadeGenero,
    sexo,
    isGestante,
    tipoEstabelecimento,
    POLICLINICA_ENABLED
  ),
]
