import { resolveName } from 'components/form/final-form/hooks/useField'
import { getIn } from 'final-form'
import firebase from 'firebase'
import { StatusRevisaoAtendimento } from 'graphql/types.generated'
import { isEqual, omit } from 'lodash'
import { isObjectDeepEmpty } from 'util/object'

import { CirurgiaInternacaoModel, PessoalAntecedentesModel } from '../detail/soap/antecedentes/model'
import { ProblemaCondicaoModel } from '../detail/soap/avaliacao/components/problemas-condicoes/model'
import { meta, SoapState } from './model'

export type LogEventType = firebase.analytics.Analytics['logEvent']

const logs: Record<string, string> = {
  [resolveName(meta.plano.odontologia.odontograma)]: 'registrar_odontograma_AO',
  [resolveName(meta.plano.odontologia.periodontia)]: 'registrar_periodontia_AO',
  [resolveName(meta.plano.odontologia.tecidosMoles)]: 'registrar_tecidos_moles_AO',
}

const logsKeys = Object.keys(logs)

export const logEventFinalizarAtendimento = (
  logEvent: LogEventType,
  nomeAtendimento: string,
  values: SoapState,
  idadeEmAnos: number,
  isGestante: boolean,
  initialValuesAntecedentesPessoal: PessoalAntecedentesModel,
  isAtendimentoObservacao: boolean,
  statusRevisaoAtendimento: StatusRevisaoAtendimento
) => {
  logEvent('finalizar_atendimento', {
    Tipo_de_atendimento: nomeAtendimento,
    Idade: idadeEmAnos,
    Gestante: isGestante,
    isAtendimentoObservacao,
    ManterCidadaoLista: values.finalizacao?.desfechoAtendimento?.manterCidadaoLista,
  })

  if (values?.plano?.startObservacao) {
    logEvent('iniciar_atend_OBS', { tipoAtendimento: values.finalizacao.tipoAtendimento })
  }

  if (statusRevisaoAtendimento === StatusRevisaoAtendimento.EM_REVISAO) {
    logEvent('revisao_realizada_EST', { tipoAtendimento: nomeAtendimento })
  }

  logsKeys.forEach((logMeta) => {
    const fieldValue = getIn(values, logMeta)

    if (!isObjectDeepEmpty(fieldValue)) {
      logEvent(logs[logMeta])
    }
  })

  setupLogEventAlteracoesAntecedentes(logEvent, values, initialValuesAntecedentesPessoal)
}

const setupLogEventAlteracoesAntecedentes = (
  logEvent: LogEventType,
  values: SoapState,
  initialValuesAntecedentesPessoal: PessoalAntecedentesModel
) => {
  const antecedentePessoal = values.antecedentes?.pessoal
  const problemasFamiliares = values.antecedentes?.familiar?.ciaps
  const listaCiapsSelecionados = problemasFamiliares
    ?.filter((value) => !value.atendimentoPrevio)
    .map((value) => value.ciap?.codigo)

  const cirurgiasInternacoes = antecedentePessoal?.cirurgiasInternacoes
  const listaCirurgiasInternacoes = cirurgiasInternacoes?.filter((value) => !value.idCirurgiaInternacao)

  const antecedentesResolvidos = values.problemasECondicoes
  const listaAntecedentesResolvidos = antecedentesResolvidos?.filter((value) => value.isAntecedentes)

  const isAlterouObstetricas = !isEqual(
    omit(antecedentePessoal?.informacoesObstetricas, ['openedAccordions']),
    initialValuesAntecedentesPessoal?.informacoesObstetricas
  )

  const isAlterouPuericultura = !isEqual(
    omit(antecedentePessoal?.puericultura, ['openedAccordions']),
    initialValuesAntecedentesPessoal?.puericultura
  )

  logEventAlteracoesAntecedentes(
    logEvent,
    listaCiapsSelecionados,
    listaCirurgiasInternacoes,
    listaAntecedentesResolvidos,
    isAlterouObstetricas,
    isAlterouPuericultura
  )
}

const logEventAlteracoesAntecedentes = (
  logEvent: LogEventType,
  listaCiapsSelecionados: string[],
  listaCirurgiasInternacoes: CirurgiaInternacaoModel[],
  listaAntecedentesResolvidos: ProblemaCondicaoModel[],
  isAlterouObstetricas: boolean,
  isAlterouPuericultura: boolean
) => {
  const isListaCiapsPreenchida = listaCiapsSelecionados?.length > 0
  const isListaCirurgiasIntPreenchida = listaCirurgiasInternacoes?.length > 0
  const isListaAntecedentesResPreenchida = listaAntecedentesResolvidos?.length > 0

  const hasAlteracoes =
    isListaCiapsPreenchida ||
    isListaCirurgiasIntPreenchida ||
    isListaAntecedentesResPreenchida ||
    isAlterouObstetricas ||
    isAlterouPuericultura

  const alteracoes = {
    ciapsSelecionadosListaProblemasFamiliares: isListaCiapsPreenchida ? listaCiapsSelecionados : 'Não alterou',
    cirurgiasInternacoes: getAlterou(isListaCirurgiasIntPreenchida),
    problemasCidadaoResolvido: getAlterou(isListaAntecedentesResPreenchida),
    informacoesObstetricas: getAlterou(isAlterouObstetricas),
    puericultura: getAlterou(isAlterouPuericultura),
  }

  hasAlteracoes && logEvent('alteracoes_antecedentes', alteracoes)
}

const getAlterou = (teveAlteracao: boolean) => {
  if (teveAlteracao) {
    return 'Alterou'
  }
  return 'Não alterou'
}
