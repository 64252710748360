/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  DropdownDivider,
  DropdownItem,
  Grid,
  Heading,
  HFlow,
  Icon,
  Tag,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { ButtonSize } from 'bold-ui/lib/components/Button/Button/ButtonSkins'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { DropdownButton } from 'components/dropdown'
import { Ellipsis } from 'components/Ellipsis'
import { useErrorHandler } from 'components/error'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { confirm } from 'components/modals/confirm'
import { HideOnScreenSize } from 'components/responsive'
import { useExcluirCidadaoMutation } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { identidadeGenero, sexo } from 'types/enums'
import Permissions from 'types/Permissions'
import { humanizeAge } from 'util/date/humanize-age'
import { JustificativaVisualizarProntuarioModal } from 'view/prontuario/justificativa/modal'

import { grupoCboVisualizarProntuario } from '../acessos'
import { ModalInformarLoginGovBr } from '../create/ModalInformarLoginGovBr'
import { CidadaoCabecalho } from '../types/CidadaoCabecalho'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'
import { useNeedLoginGovBr } from '../useNeedLoginGovBr'

export interface CidadaoCabecalhoSectionProps {
  cidadao: CidadaoCabecalho
  dataAtendimento?: number
  sizeBotaoAtualizar?: ButtonSize
  botaoVerProntuario?: boolean
  botaoVerAgendamentos?: boolean
  botaoExcluirCidadao?: boolean
  botaoSelecionar?: boolean
  callbackUrl?: string
  callbackParams?: string
}

export const CidadaoCabecalhoSection = (props: CidadaoCabecalhoSectionProps) => {
  return (
    <HFlow
      alignItems='flex-start'
      justifyContent='space-between'
      style={css`
        margin-top: 1rem;
      `}
    >
      <InformacoesCadastraisSection {...props} />
      <Acoes
        cidadao={props.cidadao}
        sizeBotaoAtualizar={props.sizeBotaoAtualizar}
        botaoVerProntuario={props.botaoVerProntuario}
        botaoVerAgendamentos={props.botaoVerAgendamentos}
        botaoExcluirCidadao={props.botaoExcluirCidadao}
        botaoSelecionar={props.botaoSelecionar}
        callbackUrl={props.callbackUrl}
        callbackParams={props.callbackParams}
      />
    </HFlow>
  )
}

export const CidadaoNomeSection = (props: CidadaoCabecalhoSectionProps) => {
  const { cidadao } = props

  return (
    <Grid alignItems='baseline' gap={0.5} wrap={false}>
      <Cell>
        {cidadao?.nomeSocial ? (
          <Heading data-testid='CidadaoCabecalho.nomeSocial' level={2}>
            {cidadao.nomeSocial.titleCase()}
            <Text css={styles.cidadaoNomeSectionBold}> (Nome social)</Text>{' '}
            <Text css={styles.cidadaoNomeSectionNormal} data-testid='CidadaoCabecalho.nome'>
              {cidadao?.nome.titleCase()}
            </Text>
            <Text css={styles.cidadaoNomeSectionNormal} data-testid='CidadaoCabecalho.sexo'>
              {' | '}
              {sexo[cidadao?.sexo]}
            </Text>{' '}
            {cidadao?.identidadeGeneroDbEnum && (
              <Text css={styles.cidadaoNomeSectionNormal} data-testid='CidadaoCabecalho.identidadeGenero'>
                {`(${identidadeGenero[cidadao.identidadeGeneroDbEnum]})`}
              </Text>
            )}
          </Heading>
        ) : (
          <Heading data-testid='CidadaoCabecalho.nome' level={2}>
            {cidadao?.nome.titleCase()}
            <Text css={styles.cidadaoNomeSectionNormal} data-testid='CidadaoCabecalho.sexo'>
              {' | '}
              {sexo[cidadao?.sexo]}
            </Text>{' '}
            {cidadao?.identidadeGeneroDbEnum && (
              <Text css={styles.cidadaoNomeSectionNormal} data-testid='CidadaoCabecalho.identidadeGenero'>
                {`(${identidadeGenero[cidadao.identidadeGeneroDbEnum]})`}
              </Text>
            )}
          </Heading>
        )}
      </Cell>
      <Cell>
        {cidadao?.faleceu && (
          <Tag style={styles.tag} data-testid='CidadaoCabecalho.marcacaoObito'>
            Óbito
          </Tag>
        )}
        {!cidadao?.ativo && (
          <Tag style={styles.tag} data-testid='CidadaoCabecalho.marcacaoInativo'>
            Inativo
          </Tag>
        )}
        {cidadao?.unificado && (
          <Tag style={styles.tag} data-testid='CidadaoCabecalho.marcacaoCadastroUnificado'>
            Cadastro unificado
          </Tag>
        )}
        {cidadao?.unificacaoBase && (
          <Tooltip text={`Cadastro originado após unificação da Base ${cidadao.unificacaoBase}`}>
            <Tag style={styles.tag} data-testid='CidadaoCabecalho.marcacaoOrigemBaseUnificada'>
              Base {cidadao.unificacaoBase.titleCase()}
            </Tag>
          </Tooltip>
        )}
      </Cell>
    </Grid>
  )
}

const IdadeSection = (props: { cidadao: CidadaoCabecalho; dataAtendimento: number }) => {
  const { cidadao, dataAtendimento } = props

  return (
    cidadao?.dataNascimento && (
      <Text
        style={css`
          white-space: nowrap;
        `}
      >
        {humanizeAge(cidadao.dataNascimento, dataAtendimento)}
        {' | Nasceu em '}
        <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} data-testid='CidadaoCabecalho.dataNascimento' />
      </Text>
    )
  )
}

const InformacoesCadastraisSection = (props: CidadaoCabecalhoSectionProps) => {
  const { cidadao, dataAtendimento } = props
  const telefone = cidadao?.telefoneCelular || cidadao?.telefoneResidencial || cidadao?.telefoneContato

  return (
    <Grid gapVertical={0}>
      <Cell size={12}>
        <CidadaoNomeSection {...props} />
      </Cell>

      <Cell size={12}>
        <Grid wrap={false}>
          <Cell>
            <VFlow vSpacing={0}>
              <IdadeSection cidadao={cidadao} dataAtendimento={dataAtendimento} />
              <HLabel title='Telefone' data-testid='CidadaoCabecalho.telefone'>
                <Telefone value={telefone}></Telefone>
              </HLabel>
            </VFlow>
          </Cell>
          <Cell>
            <VFlow vSpacing={0}>
              <HFlow hSpacing={1}>
                <HLabel title='CPF' data-testid='CidadaoCabecalho.cpf'>
                  <Cpf value={cidadao?.cpf} />
                </HLabel>
                <HLabel title='CNS' data-testid='CidadaoCabecalho.cns'>
                  {cidadao?.cns}
                </HLabel>
                <Ellipsis title={cidadao?.nomeMae?.titleCase()} maxLines={1}>
                  <HLabel title={<NomeMaeTitle />} data-testid='CidadaoCabecalho.nomeMae'>
                    {cidadao?.nomeMae?.titleCase()}
                  </HLabel>
                </Ellipsis>
              </HFlow>
              <Ellipsis title={cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome.titleCase()} maxLines={3}>
                <HLabel title='Unidade responsável' data-testid='CidadaoCabecalho.unidadeResponsavel'>
                  {cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome.titleCase()}
                </HLabel>
              </Ellipsis>
            </VFlow>
          </Cell>
        </Grid>
      </Cell>
    </Grid>
  )
}

function NomeMaeTitle() {
  return (
    <Fragment>
      <HideOnScreenSize up='lg'>Mãe</HideOnScreenSize>
      <HideOnScreenSize down='lg'>Nome da mãe</HideOnScreenSize>
    </Fragment>
  )
}

export const Acoes = (props: CidadaoCabecalhoSectionProps) => {
  const {
    cidadao,
    sizeBotaoAtualizar,
    botaoVerProntuario,
    botaoVerAgendamentos,
    botaoExcluirCidadao,
    botaoSelecionar,
    callbackUrl,
    callbackParams,
  } = props
  const { hasAuthorization, hasCboAuth } = useSession()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const excluir = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.excluir)
  const visualizarProntuario =
    hasAuthorization(Permissions.visualizarProntuario) && hasCboAuth(grupoCboVisualizarProntuario)
  const visualizarAgenda = hasAuthorization(Permissions.visualizarAgenda)

  const showBotaoVerProntuario = visualizarProntuario && botaoVerProntuario
  const showBotaoVerAgendamentos = visualizarAgenda && botaoVerAgendamentos
  const showBotaoExcluirCidadao = excluir && botaoExcluirCidadao

  const [excluirCidadao] = useExcluirCidadaoMutation()
  const handleRejection = useErrorHandler()
  const alert = useAlert()

  const history = useHistory()
  const location = useLocation()

  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir o cadastro do cidadão?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadao({ variables: { cidadaoId: cidadao?.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão excluído.')
            history.push(`/cidadao`)
          })
          .catch(handleRejection)
      },
    })()
  }
  const showBotaoAtualizarCadastro = !location.pathname.includes('atendimento-vacinacao')

  const onVisualizarProntuarioClick = () => {
    setIsModalOpen(true)
  }

  const handleVerAgendamentosClick = () => {
    cidadao && history.push(`/cidadao/${cidadao.id}/agendamentos`)
  }

  const { needLoginGovBr: deveAparecerModalGovBr } = useNeedLoginGovBr()
  const [modalInformarLoginGovBrOpen, setModalInformarLoginGovBrOpen] = useState(false)

  const continueEditarCidadao = () => {
    if (!!callbackUrl) {
      history.push(`/cidadao/${props.cidadao.id}/edit?callbackUrl=${callbackUrl}&callbackParams=${callbackParams}`)
    } else {
      history.push(`/cidadao/${props.cidadao.id}/edit${location.search}`)
    }
  }

  const onEditarClick = () => {
    if (deveAparecerModalGovBr) {
      setModalInformarLoginGovBrOpen(true)
    } else {
      continueEditarCidadao()
    }
  }

  return (
    <Fragment>
      {visualizarProntuario && botaoVerProntuario && (
        <JustificativaVisualizarProntuarioModal
          prontuarioId={cidadao?.prontuario?.id}
          cidadaoId={cidadao.id}
          isModalOpen={isModalOpen}
          onReject={() => setIsModalOpen(false)}
        />
      )}
      <VFlow vSpacing={showBotaoAtualizarCadastro ? 0.3 : 2}>
        <HFlow hSpacing={0.5} justifyContent='flex-end'>
          {cadastrarEEditar && showBotaoAtualizarCadastro && (
            <Tooltip text={!cidadao?.ativo && 'Este cadastro não pode ser atualizado pois está inativo'}>
              <Button
                disabled={!cidadao?.ativo}
                kind='primary'
                onClick={onEditarClick}
                size={sizeBotaoAtualizar}
                skin='default'
                data-testid='CidadaoCabecalho.atualizarCadastro'
              >
                Atualizar cadastro
              </Button>
            </Tooltip>
          )}
          {(showBotaoVerProntuario || showBotaoVerAgendamentos || showBotaoExcluirCidadao) && (
            <DropdownButton
              kind='normal'
              size={sizeBotaoAtualizar}
              skin='outline'
              data-testid='CidadaoCabecalho.dropDown'
            >
              {showBotaoVerProntuario && (
                <Tooltip text={!cidadao?.prontuario && 'Cadastro não possui prontuário'}>
                  <DropdownItem
                    data-testid='CidadaoCabecalho.botaoVerProntuario'
                    onClick={onVisualizarProntuarioClick}
                    disabled={!cidadao?.prontuario}
                  >
                    Ver prontuário
                  </DropdownItem>
                </Tooltip>
              )}

              {showBotaoVerAgendamentos && (
                <Tooltip text={!cidadao?.possuiAgendamento && 'Não há agendamentos registrados para o cidadão'}>
                  <DropdownItem
                    data-testid='CidadaoCabecalho.botaoVerAgendamentos'
                    onClick={handleVerAgendamentosClick}
                    disabled={!cidadao?.possuiAgendamento}
                  >
                    Ver agendamentos
                  </DropdownItem>
                </Tooltip>
              )}
              {showBotaoExcluirCidadao && (
                <Fragment>
                  <DropdownDivider />
                  <DropdownItem
                    type='danger'
                    style={{
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={handleExcluir}
                    data-testid='CidadaoCabecalho.botaoExcluir'
                  >
                    <Icon icon='trashOutline' style={{ marginRight: '0.5rem' }} /> Excluir
                  </DropdownItem>
                </Fragment>
              )}
            </DropdownButton>
          )}
        </HFlow>
        <HFlow hSpacing={0.2} alignItems='center' data-testid='CidadaoCabecalho.dataUltimaAtualizacao'>
          <Text>Última atualização em</Text>
          <DateTime format='DD/MM/YYYY' value={cidadao?.dataAtualizado} />
        </HFlow>
        {botaoSelecionar && (
          <HFlow hSpacing={0.2} justifyContent='flex-end'>
            <Button
              kind='normal'
              skin='outline'
              size='small'
              onClick={() => navigateToCidadaoCallbackUrl(props.cidadao.id)}
            >
              <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
              Selecionar e voltar
            </Button>
          </HFlow>
        )}
        <ModalInformarLoginGovBr
          open={modalInformarLoginGovBrOpen}
          onClose={() => setModalInformarLoginGovBrOpen(false)}
          onConfirm={continueEditarCidadao}
        />
      </VFlow>
    </Fragment>
  )
}

const styles = {
  tag: css`
    &:not(:last-of-type) {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  `,
  cidadaoNomeSectionBold: css`
    font-size: 1rem;
    font-weight: bold;
  `,
  cidadaoNomeSectionNormal: css`
    font-size: 0.9rem;
    font-weight: normal;
  `,
}
