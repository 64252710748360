import { Button, Cell, Grid, HFlow, Icon, Text, Tooltip, useTheme } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { PageLoading } from 'components/loading'
import { useEquipeSelectFieldQuery, useVinculacaoFichaCadastroIndividualQuery } from 'graphql/hooks.generated'
import { PerguntaSaidaCadastroEnum } from 'graphql/types.generated'
import React, { useCallback, useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import CidadaoFormModel, { VincularEquipeFormModel } from '../../types/CidadaoFormModel'
import { VincularCidadaoConcluded } from './VincularCidadaoConcluded'
import { VincularCidadaoEdit } from './VincularCidadaoEdit'

interface VincularCidadaoComponentRootProps {
  name: MetaPath<VincularEquipeFormModel>
  values?: any
  formProps: FormRenderProps<CidadaoFormModel>
  cpfCidadao: string
  cnsCidadao: string
  uuidFicha: string
  faleceu: boolean
}

export interface UsuarioLogadoVinculacao {
  unidadeSaude: {
    id: string
    nome?: string
  }
}

export interface VinculacaoFichaCadastroIndividual {
  perguntaSaidaCadastroEnum?: PerguntaSaidaCadastroEnum
  unidadeSaude?: {
    id: string
    nome?: string
  }
  equipe?: {
    id: string
    ine: string
    nome: string
    tipoEquipe: {
      id: string
      nome: string
      sigla: string
    }
  }
}

export type EstadoComponenteVinculo = 'SEM_VINCULO' | 'CADASTRAR_VINCULO' | 'VINCULO_CADASTRADO'

export const VincularCidadaoComponentRoot = (props: VincularCidadaoComponentRootProps) => {
  const { name, values, formProps, cpfCidadao, cnsCidadao, uuidFicha } = props
  const theme = useTheme()
  const vincularValues = resolveValue(values ? values : props.values, name) || ({} as VincularEquipeFormModel)
  const [componentState, setComponentState] = useState<EstadoComponenteVinculo>()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const { data: equipesUnidadeUsuarioLogado, loading: loadingEquipesUsuarioLogado } = useEquipeSelectFieldQuery({
    variables: { unidadeSaudeId: acesso?.unidadeSaude.id, input: {} },
  })

  const possuiEquipe = equipesUnidadeUsuarioLogado?.unidadeSaude?.equipes?.content?.length > 0

  const { data, loading: loadingFicha } = useVinculacaoFichaCadastroIndividualQuery({
    variables: { input: { cpfCidadao, cnsCidadao, uuidFicha } },
    fetchPolicy: 'cache-first',
  })

  const cadastroIndividualValues: VinculacaoFichaCadastroIndividual = data?.vinculacaoFichaCadastroIndividual

  const clearComponent = useCallback(() => {
    formProps.form.change(name.absolutePath(), null)
  }, [formProps.form, name])

  useEffect(() => {
    if (
      formProps.initialValues.vinculacao?.unidadeSaude ||
      formProps.initialValues.vinculacao?.cadastroIndividualCheck
    ) {
      setComponentState('VINCULO_CADASTRADO')
    } else {
      setComponentState('SEM_VINCULO')
    }
  }, [formProps.initialValues.vinculacao])

  useEffect(() => {
    if (props.faleceu) {
      setComponentState('SEM_VINCULO')
    }
  }, [props.faleceu])

  return (
    <Grid wrap>
      <Cell size={8}>
        <Text>
          A equipe responsável vinculada tem a responsabilidade de acompanhar este cidadão.
          <br />
          O vínculo pode ser com a equipe responsável pela área em que o cidadão reside ou com
          <br />
          outra equipe ou unidade de saúde.
        </Text>
      </Cell>
      {componentState === 'SEM_VINCULO' && (
        <Cell size={8}>
          <HFlow
            style={{
              border: `1px solid ${theme.pallete.divider}`,
              padding: '1rem',
            }}
            alignItems='center'
            justifyContent='space-between'
          >
            <Text fontStyle='italic'>Cidadão não possui equipe responsável vinculada.</Text>
            <Tooltip
              text={props.faleceu && 'Não é possível vincular uma equipe a um cidadão que possui óbito registrado'}
            >
              <Button
                kind='primary'
                size='small'
                onClick={() => setComponentState('CADASTRAR_VINCULO')}
                disabled={props.faleceu}
              >
                <HFlow hSpacing={0.5}>
                  <Icon icon='link' size={1.5} />
                  Vincular Equipe
                </HFlow>
              </Button>
            </Tooltip>
          </HFlow>
        </Cell>
      )}

      <Cell size={8}>
        <div style={{ display: componentState === 'CADASTRAR_VINCULO' ? 'block' : 'none' }}>
          {!loadingFicha && !loadingEquipesUsuarioLogado ? (
            <VincularCidadaoEdit
              setComponentState={setComponentState}
              name={name}
              values={vincularValues}
              usuarioLogado={acesso}
              cadastroIndividualValues={cadastroIndividualValues}
              possuiEquipe={possuiEquipe}
            />
          ) : (
            <PageLoading message='Carregando' />
          )}
        </div>
        <div style={{ display: componentState === 'VINCULO_CADASTRADO' ? 'block' : 'none' }}>
          <VincularCidadaoConcluded
            clearComponent={clearComponent}
            setComponentState={setComponentState}
            values={vincularValues}
            usuarioLogado={acesso}
          />
        </div>
      </Cell>
    </Grid>
  )
}
