import { Cell, FormControl, Grid, Paper, Text, Theme, useStyles, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ErrorField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import React, { useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Permissions from 'types/Permissions'
import { MetaPath } from 'util/metaPath'
import { meta as metaSoap, SoapState } from 'view/atendimentos/atendimento-individual/model'
import { EncaminhamentoForm } from 'view/atendimentos/detail/soap/finalizacao/components/EncaminhamentoForm'
import { EncaminhamentoInternoFormModel } from 'view/atendimentos/model'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'

export enum ManterCidadaoLista {
  SIM = 'SIM',
  NAO = 'NAO',
}

export interface DesfechoAtendimentoFormModel {
  manterCidadaoLista: ManterCidadaoLista
  atendimento?: EncaminhamentoInternoFormModel
}

export interface DesfechoAtendimentoPanelProps {
  name: MetaPath<DesfechoAtendimentoFormModel>
  agendamentosDia?: AgendamentoDia[]
  labelManterCidadaoLista?: string
  labelPanel?: string
  isAtendimentoObservacao?: boolean
  isObservacaoAndAuxiliar?: boolean
}

DesfechoAtendimentoPanel.defaultProps = {
  labelPanel: 'Desfecho do atendimento',
  labelManterCidadaoLista: 'Manter cidadão na lista de atendimentos',
} as DesfechoAtendimentoPanelProps

const TOOLTIP_START_OBSERVACAO = 'O cidadão será encaminhado para observação.'
const TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO =
  'Apenas o profissional que inseriu o cidadão em observação pode liberar o cidadão.'

export function DesfechoAtendimentoPanel(props: DesfechoAtendimentoPanelProps) {
  const {
    name,
    agendamentosDia,
    labelManterCidadaoLista,
    labelPanel,
    isAtendimentoObservacao = false,
    isObservacaoAndAuxiliar = false,
  } = props

  const {
    mutators: { changeValue },
  } = useForm<SoapState>()

  const { values: formValues } = useFormState({ subscription: { values: true } })

  const { classes } = useStyles(createStyles)

  const currentValue = resolveValue(formValues, name.manterCidadaoLista)
  const isStartObservacao = resolveValue(formValues, metaSoap.plano?.startObservacao)
  const isStartOuAtendimentoObservacao = isStartObservacao || isAtendimentoObservacao

  const manterNaListaTooltipText = isStartObservacao
    ? TOOLTIP_START_OBSERVACAO
    : isObservacaoAndAuxiliar
    ? TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO
    : ''

  useEffect(() => {
    if (isStartObservacao && currentValue === ManterCidadaoLista.NAO) {
      changeValue(name.manterCidadaoLista, ManterCidadaoLista.SIM)
    }
  }, [changeValue, currentValue, isStartObservacao, name.manterCidadaoLista])

  return (
    <FormControl label={labelPanel} required>
      <VFlow vSpacing={0.5}>
        <Grid>
          <Cell size={12}>
            <RadioFieldContainer
              name={name.manterCidadaoLista}
              value={ManterCidadaoLista.NAO}
              disabled={isStartObservacao || isObservacaoAndAuxiliar}
              tooltipText={manterNaListaTooltipText}
            >
              <Text>Liberar cidadão</Text>
            </RadioFieldContainer>
          </Cell>
          <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
            <Cell size={12}>
              <VFlow>
                <RadioFieldContainer name={name.manterCidadaoLista} value={ManterCidadaoLista.SIM}>
                  {!isStartOuAtendimentoObservacao ? (
                    <Text>{labelManterCidadaoLista}</Text>
                  ) : (
                    <Text>
                      Manter cidadão na lista de atendimentos <strong>para observação</strong>
                    </Text>
                  )}
                </RadioFieldContainer>

                {currentValue === ManterCidadaoLista.SIM && (
                  <>
                    <Paper style={classes.dadosAtendimento}>
                      <EncaminhamentoForm
                        name={name.atendimento}
                        agendamentosDia={agendamentosDia}
                        isStartOuAtendimentoObservacao={isStartOuAtendimentoObservacao}
                      />
                    </Paper>
                    <ErrorField name={name.atendimento} />
                  </>
                )}
              </VFlow>
            </Cell>
          </CheckPermission>
        </Grid>
      </VFlow>
    </FormControl>
  )
}

const createStyles = (theme: Theme) => ({
  dadosAtendimento: {
    padding: '1rem',
    borderColor: theme.pallete.gray.c60,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    marginTop: '-1.1rem',
  },
})
