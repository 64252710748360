import { FormControl } from 'bold-ui'
import { useFormControl, UseFormControlProps } from 'bold-ui/lib/hooks/useFormControl'
import { AgeRange, AgeRangeProps } from 'components/date/AgeRange'
import React from 'react'

import { UseFieldProps, usePecField } from '../final-form/hooks/useField'
import { getFieldError } from '../final-form/util'

export type AgeRangeFieldProps = UseFieldProps<any> & Omit<AgeRangeProps, 'name'> & UseFormControlProps

export function AgeRangeField(props: AgeRangeFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  const { getFormControlProps, getInputProps } = useFormControl(props)
  const inputProps = getInputProps()

  return (
    <FormControl {...getFormControlProps()} error={getFieldError(meta)}>
      <AgeRange invalid={!!getFieldError(meta)} {...inputProps} {...input} {...rest} />
    </FormControl>
  )
}
