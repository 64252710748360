import { Cell } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import CheckPermission from 'components/auth/CheckPermission'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { meta as metaSoapState } from 'view/atendimentos/atendimento-individual/model'

import { TipoPreNatal } from '../../pre-natal/model'
import { AgendarConsultaSection, AgendarConsultaSectionModel } from './AgendarConsultaSection'
import { AgendarProximasConsultasSectionModel } from './proximas-consultas/AgendarProximasConsultasSection'
import { ProximasConsultasPanel } from './proximas-consultas/ProximasConsultasPanel'

export interface AgendarConsultaPanelModel extends AgendarConsultaSectionModel {
  openedAccordions?: (string | number)[]
  proximasConsultas?: AgendarProximasConsultasSectionModel
}

export interface AgendarConsultaPanelProps {
  name: MetaPath<AgendarConsultaPanelModel>
  hasPainelLateral?: boolean
  registroTardio?: boolean
  tipoPreNatal?: TipoPreNatal
  prontuarioId?: ID
}

// TODO (Eldorado #14457): Trazer sugestões das consultas de pré-natal
export const AgendarConsultaPanel = React.memo((props: AgendarConsultaPanelProps) => {
  const { name, registroTardio, tipoPreNatal, prontuarioId } = props

  const { AGENDAMENTOS_PRE_NATAL_ENABLED } = useFlags()
  const {
    input: { value: idadeGestacional },
  } = usePecField({ name: metaSoapState.acompanhamentoPreNatal.idadeGestacional })

  const hasIdadeGestacional = !isUndefinedOrNull(idadeGestacional)
  const isInicioOuContinuacaoPreNatal =
    tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL || tipoPreNatal === TipoPreNatal.CONTINUACAO_PRE_NATAL
  const shouldRenderProximasConsultas =
    hasIdadeGestacional && isInicioOuContinuacaoPreNatal && AGENDAMENTOS_PRE_NATAL_ENABLED === true

  return (
    <CheckPermission permission={Permissions.visualizarAgenda.agendar}>
      <FormSectionAccordion
        name={name.openedAccordions}
        title='Agendar consulta'
        uuid='agendarConsulta'
        dangerouslySetExpanded={shouldRenderProximasConsultas}
      >
        <AgendarConsultaSection name={name} registroTardio={registroTardio} />
        {shouldRenderProximasConsultas && AGENDAMENTOS_PRE_NATAL_ENABLED && (
          <Cell size={12}>
            <ProximasConsultasPanel name={name.proximasConsultas} prontuarioId={prontuarioId} />
          </Cell>
        )}
      </FormSectionAccordion>
    </CheckPermission>
  )
})
