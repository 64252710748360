/** @jsx jsx */
import { css, jsx } from '@emotion/core'

interface GovBrButtonProps {
  href: string
}

export const GovBrButton = (props: GovBrButtonProps) => {
  const { href } = props

  return (
    <a href={href} css={govBrStyles.button}>
      <span>
        Entrar com <b>gov.br</b>
      </span>
    </a>
  )
}

const govBrTheme = {
  textColor: '#1351B4',
  backgroundDefault: '#F8F8F8',
  backgroundHover: 'rgba(19, 81, 180, 0.16)', // #D3DDED
  backgroundActive: 'rgba(19, 81, 180, 0.45)', // '#90ACD9
  disabledOpacity: 0.45,
}

export const govBrStyles = {
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;

    padding: 0 1.5rem;
    border-radius: 2.5rem;
    background: ${govBrTheme.backgroundDefault};

    color: ${govBrTheme.textColor};
    font-size: 1rem;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background: ${govBrTheme.backgroundHover};
    }

    &:active {
      background: ${govBrTheme.backgroundActive};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: ${govBrTheme.disabledOpacity};
    }
  `,
}
