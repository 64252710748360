import { blue, ColorScale, gray, green, orange, red } from 'bold-ui/lib/styles/colors'
import { AgendaEventModel, TimeInterval, Weekday } from 'components/agenda'
import {
  CidadaoAtendimentoSelectFieldModel,
  HorarioAgendaSelectFieldModel,
  LocalAtendimentoSelectFieldModel,
} from 'components/form'
import {
  AgendamentosDiaLotacaoQuery,
  MotivoAgendamentoReservaEnum,
  OrigemAgendamentoEnum,
  SituacaoAgendadoEnum,
  SituacaoSincronizacaoAgendaOnlineEnum,
  StatusAtendimento,
  StatusDiaAgendaEnum,
  TipoAgendamentoEnum,
} from 'graphql/types.generated'
import { JustificativaCancelamentoAgendaComboEnum } from 'types/enums'

import { StatusSincronizacaoAgendaOnlineProps } from './components/listagem/StatusSincronizacaoAgendaOnline'

export type AgendamentoQueryModel = AgendamentosDiaLotacaoQuery['agendados']['content'][number]

const TIME_INTERVAL_FECHAMENTO: TimeInterval[] = [{ start: { hours: 7, minutes: 0 }, end: { hours: 19, minutes: 0 } }]
export const AVAILABLE_TIMES_FECHAMENTO: Record<Weekday, TimeInterval[]> = {
  [Weekday.MONDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.FRIDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.SATURDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.SUNDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.THRUSDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.TUESDAY]: TIME_INTERVAL_FECHAMENTO,
  [Weekday.WEDNESDAY]: TIME_INTERVAL_FECHAMENTO,
}

export interface AtendimentoEventoAgendaLotacao {
  id: ID
  situacao: StatusAtendimento
  possuiAtendimentosProfissionais: boolean
}

export enum TipoAgendamento {
  CONSULTA = 'consulta',
  RESERVA = 'reserva',
  SEM_CONFIGURACAO = 'sem_configuracao',
  FECHAMENTO = 'fechamento',
}

export interface EventoAgendaLotacao extends AgendaEventModel {
  id: ID
  tipo: TipoAgendamento
  origem?: OrigemAgendamentoEnum
  situacao?: SituacaoAgendadoEnum
  situacaoSincronizacao?: SituacaoSincronizacaoAgendaOnlineEnum
  observacoes?: string
  motivoReserva?: string
  isOutroMotivoReserva?: boolean
  telefoneCidadao?: string
  nomeCidadao?: string
  cidadaoId?: ID
  prontuarioId?: ID
  isForaUbs?: boolean
  localAtendimento?: string
  lotacao?: LotacaoAgendaModel
  dataCriacao?: Instant
  atendimento?: AtendimentoEventoAgendaLotacao
  isAtencaoDomiciliar?: boolean
  dataInicioFechamento?: Date
  dataFimFechamento?: Date
}

export interface AgendamentoReservaModel {
  horario: HorarioAgendaSelectFieldModel
  data: LocalDate
  motivoReserva: MotivoAgendamentoReservaEnum
  especificacaoMotivoReserva: string
  observacoes: string
}

export interface DiaModel {
  data: Date | string
  status: StatusDiaAgendaEnum
}

export const statusDiasAgendaRecord: Partial<Record<
  StatusDiaAgendaEnum,
  { hint: string; highlightColor?: ColorScale }
>> = {
  DISPONIVEL: { hint: 'Data disponível', highlightColor: green },
  INDISPONIVEL: { hint: '', highlightColor: null },
}

export const motivoReservaRecord: Record<MotivoAgendamentoReservaEnum, { nome: string }> = {
  REUNIAO: { nome: 'Reunião' },
  OUTRO: { nome: 'Outros' },
  ATIVIDADE_COLETIVA: { nome: 'Atividade coletiva' },
  ATENDIMENTO_EXTERNO: { nome: 'Atendimento externo' },
}

export const situacaoAgendamentoRecord: Record<SituacaoAgendadoEnum, { descricao: string; cor?: ColorScale }> = {
  AGENDADO: { descricao: 'Agendado' },
  ATENDIMENTO_REALIZADO: { descricao: 'Atendimento realizado', cor: blue },
  CANCELADO: { descricao: 'Cancelado', cor: orange },
  CIDADAO_PRESENTE_NA_UNIDADE: { descricao: 'Cidadão presente na unidade', cor: green },
  EXCLUIDO: { descricao: 'Excluído' },
  NAO_AGUARDOU: { descricao: 'Cidadão não aguardou', cor: gray },
  NAO_COMPARECEU: { descricao: 'Cidadão não compareceu', cor: red },
}

export interface AgendamentoConsultaModel {
  id: ID
  cidadao: CidadaoAtendimentoSelectFieldModel
  tipoAgendamento: TipoAgendamentoEnum
  situacao: SituacaoAgendadoEnum
  horario: HorarioAgendaSelectFieldModel
  data: LocalDate
  observacoes: string
  localAtendimento: LocalAtendimentoSelectFieldModel
  telefoneCelular: string
  email: string
}

export interface ResumoCidadaoModel {
  id: ID
  nome: string
  nomeSocial?: string
  cpf?: string
  cns?: string
  dataNascimento?: string
  contato?: {
    telefoneCelular?: string
    telefoneContato?: string
    telefoneResidencial?: string
  }
}

export interface LotacaoAgendaModel {
  id: ID
  ativo: boolean
  importada?: boolean
  hasConfiguracaoAgenda?: boolean
  hasConfiguracaoAgendaOnline?: boolean
  hasAgendamentoFuturo?: boolean
  profissional: {
    id: ID
    nome: string
  }
  cbo: {
    id: ID
    nome: string
    cbo2002: string
  }
  equipe?: {
    id: ID
    ine: string
    nome: string
    tipoEquipe?: {
      id: ID
      descricao?: string
    }
  }
}

export interface JustificativaCancelamentoAgendamentoModel {
  id: ID
  justificativa?: JustificativaCancelamentoAgendaComboEnum
  justificativaOutra?: string
}

export const situacaoSincronizacaoAgendaOnlineRecord: Omit<
  Record<
    SituacaoSincronizacaoAgendaOnlineEnum,
    Omit<StatusSincronizacaoAgendaOnlineProps, 'expanded'> & { mostrarMinimizado: boolean }
  >,
  'INELEGIVEL'
> = {
  AGUARDANDO_ELEGIBILIDADE_CADASTRO_CIDADAO: {
    mostrarMinimizado: true,
    icon: 'exclamationTriangleOutline',
    color: orange,
    tooltip: 'Atualize o CNS do cidadão para sincronizar com a Agenda online',
    text: 'Atualize o CNS do cidadão para sincronizar o agendamento com o servidor da agenda online',
  },
  AGUARDANDO_SINCRONIZACAO: {
    mostrarMinimizado: false,
    icon: 'sync',
    color: orange,
    text: 'Aguardando sincronização com o servidor da agenda online',
  },
  AGUARDANDO_SINCRONIZACAO_ATUALIZACAO: {
    mostrarMinimizado: false,
    icon: 'sync',
    color: orange,
    text: 'Aguardando sincronização com o servidor da agenda online',
  },
  ANTERIOR_ATIVACAO_AGENDA_ONLINE: {
    mostrarMinimizado: false,
    icon: 'clockOutline',
    color: gray,
    text: 'Este agendamento é anterior à ativação da agenda online',
  },
  REGISTRO_INCONSISTENTE: {
    mostrarMinimizado: true,
    icon: 'banOutline',
    color: red,
    tooltip: 'Não foi possível sicronizar com o servidor da agenda online',
    text: 'Este agendamento não pode ser sincronizado com o servidor da agenda online',
    link: { text: 'Entre em contato com o portal de suporte', url: 'http://esusaps.bridge.ufsc.br/support/login' },
  },
  SINCRONIZADO: {
    mostrarMinimizado: false,
    icon: 'checkCircleOutline',
    color: green,
    text: 'Sincronizado com o servidor da agenda online',
  },
}
