import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV51() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.1.24' id='estabilizacao-5-1-24'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenários onde o sistema apresentava erro caso houvesse cadastro do mesmo INE em mais de um
            estabelecimento.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.1.21' id='estabilizacao-5-1-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Simplificação dos campos "Atendimento fora da UBS" e "Local de atendimento" no agendamento.
          </Text>
          <Text component='li'>Correção na lentidão encontrada na seleção de profissionais no módulo da Agenda.</Text>
          <Text component='li'>Destaque no calendário da Agenda para datas com horários disponíveis.</Text>
          <Text component='li'>
            Correção no relatório CSV ao aplicar os filtros avançados, para exibir todos os imunobiológicos daquele
            cidadão e não apenas os que foram selecionados.
          </Text>
          <Text component='li'>
            Correção de cenário onde a lotação de estagiário era impossibilitada de imprimir solicitações de exames.
          </Text>
          <Text component='li'>
            Correção na prescrição de medicamentos onde as comboboxes não fechavam após confirmar seleção com atalhos do
            teclado.
          </Text>
          <Text component='li'>
            Correção da exportação da série histórica nos relatórios CSV, para voltarem a apresentar o CNS dos
            profissionais.
          </Text>
          <Text component='li'>
            Correção do fluxo que solicitava múltiplas vezes a Justificativa de acesso ao prontuário para um mesmo
            cidadão.
          </Text>
          <Text component='li'>
            Retirada do item de checkbox em branco da lista do Tipo de atendimento do Histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Correção de cenário onde o sistema apresentava uma tela branca após o login para alguns usuários.
          </Text>
          <Text component='li'>Correção no módulo de Agenda para agendamentos exibirem o telefone do cidadão.</Text>
          <Text component='li'>Adicionada compatibilidade com a versão 3.1 do XML do CNES.</Text>
          <Text component='li'>
            Inclusão do perfil de Farmacêutico para realizar prescrição de medicamentos no Atendimento individual.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.1.20' id='estabilizacao-5-1-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde nenhuma informação era apresentada no Relatório de inconsistências caso houvesse
            erro ao enviar fichas.
          </Text>
          <Text component='li'>
            Correção de erro onde uma tela branca era apresentada ao navegar no módulo de Reterritorialização.
          </Text>
          <Text component='li'>
            Correção do Relatório operacional de inconsistências do Cadastro territorial para exibição correta de
            famílias que foram reterritorializadas.
          </Text>
          <Text component='li'>
            Correção de erro onde atendimentos realizados no aplicativo e-SUS Atenção Domiciliar para cidadãos sem CPF e
            sem CNS não eram apresentados no histórico clínico.
          </Text>
          <Text component='li'>
            Correção de cenário onde a idade gestacional ecográfica era apresentada diferente entre a impressão e o card
            de acompanhamento.
          </Text>
          <Text component='li'>
            Ajustes para exibir a data em que o atendimento foi realizado na Declaração de comparecimento do Registro
            tardio.
          </Text>
          <Text component='li'>
            Alterações e melhorias na busca de dados do Acompanhamento de cidadãos vinculados visando performance.
          </Text>
          <Text component='li'>
            A mensagem informativa referente a lista de CID10 e CIAP2 que aumentam o risco da gravidez apresentará as
            CID10 apenas para profissionais que possam preencher essa informação.
          </Text>
          <Text component='li'>
            Correção do cenário onde a idade gestacional não era exibida no card do acompanhamento de pré-natal quando
            acessado através do módulo do cidadão.
          </Text>
          <Text component='li'>
            Correção do cenário onde não estava sendo possível imprimir o acompanhamento de pré-natal.
          </Text>
          <Text component='li'>
            Atualizado comportamento do botão para logar no gov.br para abrir uma pop-up no sistema e não mais
            redirecionar a página atual.
          </Text>
          <Text component='li'>
            Correção do cenário para que o risco da gestação seja alterado somente quando preenchido por médico ou
            enfermeiro.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.1.19' id='estabilizacao-5-1-19'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro na sincronização com o CADSUS e edição do cidadão que gerava carregamento infinito em
            algumas instalações.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.1.18' id='estabilizacao-5-1-18'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário, onde não era possível adicionar um mesmo cidadão na lista de Atenção Domiciliar por
            diferentes profissionais.
          </Text>
          <Text component='li'>
            Ajustes na seleção de checkbox via atalho de teclado no Histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Correção do cálculo da IG eco para considerar a data atual, anteriormente apresentada de forma fixa a data
            informada no resultado da USG.
          </Text>
          <Text component='li'>Ajustes no processo de preparação e de envio de lotes.</Text>
          <Text component='li'>
            Correção de cenário onde era apresentado tela branca ao tentar finalizar um atendimento com condição W78
            adicionada e, posteriormente, excluída na lista de problemas e condições.
          </Text>
          <Text component='li'>
            Adicionada funcionalidade de "Copiar atividade" para o tipo de atividade "5" na Ficha de atividade coletiva.
          </Text>
          <Text component='li'>
            Correção de cenário onde não era possível evoluir uma condição adicionada através do modal da Lista de
            problemas e condições.
          </Text>
          <Text component='li'>
            Realizados ajustes de layout visando maior visibilidade no registro de procedimentos do odontograma, além de
            adicionada a possibilidade de replicar procedimentos entre os dentes.
          </Text>
          <Text component='li'>
            Correção de cenário onde o histórico de resultados de exames aparecia desabilitado mesmo havendo resultados
            previamente registrados.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.1.17' id='estabilizacao-5-1-17'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde não era possível acessar o módulo de CBO em instalações com banco de dados Oracle.
          </Text>
          <Text component='li'>
            Ajuste de cenário onde, ao incluir CID 10 relacionado a puerpério, o tipo do atendimento será definido como
            puerpério.
          </Text>
          <Text component='li'>
            Correção de erro onde, a condição W78 adicionada automaticamente na lista de problemas e condições
            apresentava data de início incorreta, ao invés de mostrar a mesma data de início da condição de alto risco
            adicionada na avaliação.
          </Text>
          <Text component='li'>
            Correção de cenário onde, ao mudar a situação de uma condição de alto risco ou desfecho na avaliação, a data
            selecionada era alterada para o dia atual.
          </Text>
          <Text component='li'>
            Ajustes no Histórico clínico do cidadão para apresentar o nome do estagiário que realizou o atendimento.
          </Text>
          <Text component='li'>
            Ajuste no horário de entrada da declaração de comparecimento do cidadão para considerar toda a permanência
            do cidadão na unidade de saúde.
          </Text>
          <Text component='li'>
            Correção de erro onde a opção para baixar impressões não estava funcionando corretamente.
          </Text>
          <Text component='li'>
            Correção de cenário onde uma tela branca era apresentada ao tentar visualizar Atendimentos domiciliares com
            mais de um CIAP no histórico Clínico do cidadão.
          </Text>
          <Text component='li'>
            Ajustes no Histórico clínico do cidadão para exibir a concentração dos medicamentos prescritos ao lado do
            seu princípio ativo.
          </Text>
          <Text component='li'>
            Correção de erro onde o campo conduta era apresentado duas vezes no histórico de atendimentos de CEO.
          </Text>
          <Text component='li'>
            Incluído comportamento de carregamento no botão "Adicionar" da Lista de atendimentos.
          </Text>
          <Text component='li'>
            Correção no Histórico clínico do cidadão ao tentar visualizar registros de Atendimento domiciliar sem
            procedimento.
          </Text>
          <Text component='li'>
            Correção do cenário em que um mesmo imóvel era listado incorretamente múltiplas vezes no módulo de
            Reterritorialização.
          </Text>
          <Text component='li'>
            Correção de erro onde o botão de importar CNES do módulo de Unidades de saúde não estava funcionando.
          </Text>
          <Text component='li'>Melhoria no layout dos Exames solicitados no Histórico clínico do cidadão.</Text>
          <Text component='li'>
            Correção de erro onde não era possível finalizar atendimentos de pré-natal em instalações com banco de dados
            Oracle.
          </Text>
          <Text component='li'>
            Ajustes no processamento de dados para o acompanhamento de cidadãos vinculados ocorrer apenas no horário
            configurado para geração de lotes e processamento de fichas.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
