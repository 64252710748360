/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HeadingSection, Icon, isEmpty, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { ButtonLink, PrivateRoute } from 'components/route'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useCallback, useMemo } from 'react'
import { Switch, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'

import { grupoCboPrescricao } from '../acessos'
import AccordionPrescricao from './components/AccordionPrescricao'
import HistoricoPrescricaoTable from './historico-prescricao/HistoricoPrescricaoTable'
import AdicionarPrescricaoModal from './modal/AdicionarPrescricaoModal'
import { MedicamentoFormModel } from './model'

export interface PrescricaoMedicamentoViewProps {
  name: MetaPath<PrescricaoMedicamentoPlanoModel>
  prontuarioId: ID
  atendimentoId: ID
  medicamentos: MedicamentoFormModel[]
  isAtendimentoProcedimentos: boolean
  dataReferencia: Instant
}

export interface PrescricaoMedicamentoPlanoModel {
  medicamentos: MedicamentoFormModel[]
  replicateStorage: MedicamentoFormModel[]
  alreadyPrinted: ID[]
  medicamentoIdsOnPrescricaoDigital: ID[]
}

export default function PrescricaoMedicamentoView(props: PrescricaoMedicamentoViewProps) {
  const { name, prontuarioId, atendimentoId, medicamentos, isAtendimentoProcedimentos, dataReferencia } = props
  const match = useRouteMatch()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const {
    observacao: { isAtendimentoObservacao },
    atendimentoProfissional: { id: atendimentoProfissionalId },
  } = useAtendimentoContext()

  const hasPrescricao = medicamentos && !isEmpty(medicamentos)
  const podeAdicionarPrescricao = !isAtendimentoProcedimentos && hasCboAuth(grupoCboPrescricao.adicionar)

  const {
    input: { onChange: onChangeReplicate, value },
  } = usePecField({ name: name.replicateStorage })

  const handleOnClearReplicate = useCallback(() => onChangeReplicate(null), [onChangeReplicate])

  return useMemo(
    () => (
      <React.Fragment>
        <HeadingSection level={4} title='Prescrição de medicamentos' vSpace={8} />
        {podeAdicionarPrescricao && (
          <VFlow>
            <ButtonLink
              kind='primary'
              size='small'
              to={`${match.url}/prescricao-medicamentos/adicionar`}
              data-testid='PrescricaoMedicamentos.AdicionarPrescricao'
            >
              <Icon
                icon={hasPrescricao ? 'penOutline' : 'plus'}
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              {hasPrescricao ? `Editar prescrição` : `Adicionar prescrição`}
            </ButtonLink>
          </VFlow>
        )}
        <Switch>
          <PrivateRoute
            path={`${match.url}/prescricao-medicamentos/adicionar`}
            permission={grupoCboPrescricao.adicionar}
          >
            <AdicionarPrescricaoModal
              name={name}
              atendimentoId={atendimentoId}
              medicamentosToReplicate={value}
              onChangeMedicamentosToReplicate={onChangeReplicate}
              onClearMedicamentosToReplicate={handleOnClearReplicate}
            />
          </PrivateRoute>
        </Switch>
        {hasPrescricao && podeAdicionarPrescricao && (
          <VFlow
            style={css`
              margin-top: 1rem;
            `}
          >
            <AccordionPrescricao medicamentos={medicamentos} atendimentoId={atendimentoId} />
          </VFlow>
        )}
        <VFlow
          style={css`
            margin-top: 1rem;
          `}
        >
          <HistoricoPrescricaoTable
            prontuarioId={prontuarioId}
            isAtendimentoProcedimento={isAtendimentoProcedimentos}
            isAtendimentoObservacao={isAtendimentoObservacao}
            atendimentoProfissionalId={atendimentoProfissionalId}
            setMedicamentosToReplicate={onChangeReplicate}
            dataReferencia={dataReferencia}
          />
        </VFlow>
      </React.Fragment>
    ),
    [
      podeAdicionarPrescricao,
      match.url,
      hasPrescricao,
      name,
      atendimentoId,
      value,
      onChangeReplicate,
      handleOnClearReplicate,
      medicamentos,
      prontuarioId,
      isAtendimentoProcedimentos,
      isAtendimentoObservacao,
      atendimentoProfissionalId,
      dataReferencia,
    ]
  )
}
