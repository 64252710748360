/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { FooterButton } from 'components/footer-button'
import {
  CidadaoAtendimentoSelectField,
  Form,
  FormRenderProps,
  LocalAtendimentoSelectField,
  TimeField,
} from 'components/form'
import { RegistroTardioJustificativaSelectField } from 'components/form/field/select/JustificativaRegistroTardioSelectField/RegistroTardioJustificativaSelectField'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { DateField } from 'components/form/final-form/DateField/DateField'
import { subDays } from 'date-fns'
import { FormApi } from 'final-form'
import { useAtendimentoRegistroTardioQuery, useEditarRegistroTardioMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import Permissions from 'types/Permissions'
import { formatHoursMinutes, safeDateAsYyyyMmDd } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { calculator } from 'view/registro-tardio/calculator'
import { DayDelimiterTooltip } from 'view/registro-tardio/components/DayDelimiterTooltip'
import { convertModelToInput } from 'view/registro-tardio/converter'
import { REGISTRO_TARDIO_DEFAULT_DATE_DIFF } from 'view/registro-tardio/filter/useListaRegistroTardioFilterDefault'
import { RegistroTardioFormModel } from 'view/registro-tardio/model'
import { REGISTRO_TARDIO_PATH } from 'view/registro-tardio/RegistroTardioRootView'
import { isLocalAtendimentoUbs, validator } from 'view/registro-tardio/validator'

interface UrlParams {
  atendimentoId: string
}

const path = metaPath<RegistroTardioFormModel>()

export const EditarRegistroTardioModalForm = () => {
  const history = useHistory()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const [editarRegistroTardio] = useEditarRegistroTardioMutation()
  const { atendimentoId } = useParams<UrlParams>()
  const { getServerTimeNow } = useServerTime()
  const currentServerTime = getServerTimeNow()
  const minDate = subDays(currentServerTime, REGISTRO_TARDIO_DEFAULT_DATE_DIFF)
  const handleRejection = useErrorHandler()
  const alert = useAlert()

  const { hasAuthorization } = useSession()
  const canAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)

  const { data } = useAtendimentoRegistroTardioQuery({ variables: { id: atendimentoId } })
  const { justificativa, iniciadoEm, cidadao, responsavel, localAtendimento } = data.atendimento || {}

  const decorator = [calculator]

  const initialValues: RegistroTardioFormModel = useMemo(
    () => ({
      atendimentoId,
      cidadao,
      lotacao: responsavel as LotacaoAndEstagioSelectFieldModel,
      localAtendimento,
      dataAtendimento: safeDateAsYyyyMmDd(iniciadoEm),
      horaAtendimento: formatHoursMinutes(iniciadoEm),
      justificativa,
    }),
    [atendimentoId, cidadao, iniciadoEm, justificativa, localAtendimento, responsavel]
  )

  const goBack = () => {
    history.replace(REGISTRO_TARDIO_PATH, '')
  }

  const handleSubmit = (values: RegistroTardioFormModel, formApi: FormApi) => {
    editarRegistroTardio({
      variables: { input: convertModelToInput(values) },
    })
      .then((res) => {
        alert('success', `Registro alterado com sucesso.`)
        setTimeout(formApi.reset)
        return goBack()
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps<RegistroTardioFormModel>) => {
    const { values } = formProps

    const isNotAtendimentoUbs = !values.localAtendimento || !isLocalAtendimentoUbs(values.localAtendimento.id)

    return (
      <Modal
        open
        onClose={goBack}
        closeOnBackdropClick={false}
        style={css`
          width: 100%;
          max-width: 33rem;
        `}
      >
        <ModalBody
          style={css`
            margin-right: 2rem;
          `}
        >
          <Grid alignItems='flex-start' gapVertical={1}>
            <Cell size={12}>
              <Heading level={1}>Editar item na lista de registro tardio</Heading>
            </Cell>
            <Cell size={11}>
              <CidadaoAtendimentoSelectField
                name={path.cidadao}
                label='Cidadão'
                addCidadaoCallbackUrl={REGISTRO_TARDIO_PATH}
                verificarPresenteNaListaAtendimento={false}
                required
              />
            </Cell>
            {!canAtender && (
              <Cell size={11}>
                <LotacaoAndEstagioSelectField
                  name={path.lotacao}
                  label='Profissional'
                  unidadeSaudeId={acesso?.unidadeSaude.id}
                  canWriteRegistroTardio
                  required
                />
              </Cell>
            )}
            <Cell size={5}>
              <DateField
                name={path.dataAtendimento}
                label='Data do atendimento'
                required
                minDate={minDate}
                maxDate={currentServerTime}
                calendarProps={{
                  renderDay: (day: Date) => (
                    <DayDelimiterTooltip day={day} minDate={minDate} maxDate={currentServerTime} />
                  ),
                }}
              />
            </Cell>
            <Cell size={5}>
              <TimeField name={path.horaAtendimento} label='Hora' placeholder='hh:mm' required />
            </Cell>
            <Cell size={11}>
              <LocalAtendimentoSelectField name={path.localAtendimento} label='Local do atendimento' required />
            </Cell>
            <Cell size={11}>
              <RegistroTardioJustificativaSelectField
                name={path.justificativa}
                label='Justificativa'
                required={!isNotAtendimentoUbs}
                disabled={isNotAtendimentoUbs}
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <FooterButton title='Cancelar' onClick={goBack} size='medium'>
              Cancelar
            </FooterButton>
            <FooterButton title='Salvar' kind='primary' onClick={formProps.handleSubmit} size='medium'>
              Salvar
            </FooterButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<RegistroTardioFormModel>
      render={renderForm}
      initialValues={initialValues}
      decorators={decorator}
      validate={validator(minDate, canAtender)}
      onSubmit={handleSubmit}
    />
  )
}
