import createDecorator from 'final-form-calculate'
import {
  GrupoAlvoVacinacaoEnum,
  StatusVacinacaoEnum,
  TipoVisualizacaoBuscaAtivaVacinacaoEnum,
} from 'graphql/types.generated'

import { GrupoAlvoVacinacaoSelectModel } from './component/GrupoAlvoVacinacaoSelectField'
import { items } from './component/StatusDoseImunoSelectField'
import { BuscaAtivaVacinacaoFormModel, metaBuscaAtivaVacinacao } from './model'

export const calculator = createDecorator(
  {
    field: metaBuscaAtivaVacinacao.grupoAlvo.absolutePath(),
    updates: {
      [metaBuscaAtivaVacinacao.status.absolutePath()]: (
        field: GrupoAlvoVacinacaoSelectModel,
        allValues: BuscaAtivaVacinacaoFormModel
      ) => {
        return allValues.tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL &&
          field?.enum === GrupoAlvoVacinacaoEnum.GESTANTE
          ? items[StatusVacinacaoEnum.ATRASADA]
          : allValues.status
      },
    },
  },
  {
    field: metaBuscaAtivaVacinacao.tipoVisualizacao.absolutePath(),
    updates: {
      [metaBuscaAtivaVacinacao.grupoAlvo.absolutePath()]: (
        _: TipoVisualizacaoBuscaAtivaVacinacaoEnum,
        allValues: BuscaAtivaVacinacaoFormModel,
        prevValues: BuscaAtivaVacinacaoFormModel
      ) => (!prevValues.tipoVisualizacao ? allValues.grupoAlvo : null),
      [metaBuscaAtivaVacinacao.filtrosAvancados?.imunobiologicos.absolutePath()]: (
        _: TipoVisualizacaoBuscaAtivaVacinacaoEnum,
        allValues: BuscaAtivaVacinacaoFormModel,
        prevValues: BuscaAtivaVacinacaoFormModel
      ) => (!prevValues.tipoVisualizacao ? allValues.filtrosAvancados?.imunobiologicos : null),
      [metaBuscaAtivaVacinacao.filtrosAvancados?.doses.absolutePath()]: (
        _: TipoVisualizacaoBuscaAtivaVacinacaoEnum,
        allValues: BuscaAtivaVacinacaoFormModel,
        prevValues: BuscaAtivaVacinacaoFormModel
      ) => (!prevValues.tipoVisualizacao ? allValues.filtrosAvancados?.doses : null),
      [metaBuscaAtivaVacinacao.status.absolutePath()]: (
        tipoBuscaAtivaVacinacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum,
        allValues: BuscaAtivaVacinacaoFormModel,
        prevValues: BuscaAtivaVacinacaoFormModel
      ) =>
        tipoBuscaAtivaVacinacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.COVID_19
          ? items[StatusVacinacaoEnum.APLICADA]
          : !prevValues.tipoVisualizacao
          ? allValues.status
          : null,
    },
  },
  {
    field: metaBuscaAtivaVacinacao.microareas.absolutePath(),
    updates: {
      [metaBuscaAtivaVacinacao.microareas.absolutePath()]: (
        field: string[],
        allValues: BuscaAtivaVacinacaoFormModel
      ) => {
        return field?.length === 0 ? undefined : allValues.microareas
      },
    },
  }
)
