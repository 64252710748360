import { SelectField } from 'components/form'
import { GrupoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import { GrupoAtendimentoSelectFieldQuery, GrupoAtendimentoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type GrupoAtendimentoSelectFieldModel = GrupoAtendimentoSelectFieldQuery['gruposAtendimento'][0]

export interface GrupoAtendimentoSelectFieldProps extends AsyncSelectFieldProps<GrupoAtendimentoSelectFieldModel> {}

const extractItems = (data: GrupoAtendimentoSelectFieldQuery) => data?.gruposAtendimento

const handleItemToString = (item: GrupoAtendimentoSelectFieldModel) => item?.nome

const variables = (inputQuery: string): GrupoAtendimentoSelectFieldQueryVariables => ({
  inputQuery: inputQuery,
})

export function GrupoAtendimentoSelectField(props: GrupoAtendimentoSelectFieldProps) {
  const { selectProps } = useAsyncQuerySelect<
    GrupoAtendimentoSelectFieldModel,
    GrupoAtendimentoSelectFieldQuery,
    GrupoAtendimentoSelectFieldQueryVariables
  >({
    query: GrupoAtendimentoSelectFieldDocument,
    extractItems,
    variables,
    fetchPolicy: 'cache-first',
  })

  return <SelectField<GrupoAtendimentoSelectFieldModel> itemToString={handleItemToString} {...selectProps} {...props} />
}
