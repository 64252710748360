/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeadingSection, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import Permissions from 'types/Permissions'
import { MetaPath } from 'util/metaPath'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { grupoCboAtestado } from '../acessos'
import AtestadoCadastroView from './AtestadoCadastroView'
import AtestadoModel from './components/AtestadoModel'
import { AtestadoSelectModel } from './components/AtestadoSelectField'
import DeclaracaoComparecimentoBox from './DeclaracaoComparecimentoBox'
import AtestadosTable from './list/AtestadosTable'

export interface AtestadoViewProps {
  name: MetaPath<AtestadoPlanoModel>
  atendimentoId: ID
  prontuarioId: ID
  cidadao: CidadaoAtendimento
  atendimentoProfissional: AtendimentoProfissional
}

export interface AtestadoPlanoModel {
  atestadoModelo: AtestadoSelectModel
  atestadosRecentes?: AtestadoModel[]
}

export default function AtestadoView(props: AtestadoViewProps) {
  const { name, atendimentoId, prontuarioId, cidadao, atendimentoProfissional } = props

  const {
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const renderFieldArray = (fieldArrayRenderProps: FieldArrayRenderProps<AtestadoModel, any>) => {
    const {
      fields: { push, remove, update, value },
    } = fieldArrayRenderProps

    const handleSubmit = (values: AtestadoModel) => {
      push(values)
    }

    return (
      <Fragment>
        <HeadingSection level={4} title='Atestados' vSpace={8} />
        <VFlow>
          <BoxGroup>
            <CheckPermission permission={grupoCboAtestado.adicionar}>
              <AtestadoCadastroView
                cidadao={cidadao}
                atendimentoProfissional={atendimentoProfissional}
                handleSubmit={handleSubmit}
              />
            </CheckPermission>
            <CheckPermission permission={Permissions.visualizarListaDeAtendimento.gerarDeclaracaoDeComparecimento}>
              <DeclaracaoComparecimentoBox atendimentoId={atendimentoId} />
            </CheckPermission>
          </BoxGroup>
          <AtestadosTable
            prontuarioId={prontuarioId}
            atendimentoId={atendimentoId}
            dataReferencia={atendimentoProfissional.iniciadoEm}
            cidadao={cidadao}
            lotacao={atendimentoProfissional.lotacao}
            isAtendimentoObservacao={isAtendimentoObservacao}
            atendimentoProfissionalId={atendimentoProfissional.id}
            atestadosRecentes={value}
            remove={remove}
            update={update}
          />
        </VFlow>
      </Fragment>
    )
  }

  return <FieldArray name={resolveName(name.atestadosRecentes)} render={renderFieldArray} />
}
