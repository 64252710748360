import ApolloClient from 'apollo-client'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { Calculation } from 'final-form-calculate'
import { AgendadoAtendimentoFormDocument, EquipeAtendimentoDocument } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { isEqual } from 'lodash'
import { TipoServicoPadraoRecord } from 'types/enums'
import { isCboCirurgiaoDentista } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { MetaRoot } from 'util/metaPath'
import { EncaminhamentoInternoFormModel } from 'view/atendimentos/model'

const equipeCalculator = (
  meta: MetaRoot<EncaminhamentoInternoFormModel>,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  apollo: ApolloClient<object>
) => [
  {
    field: meta.responsavel.absolutePath(),
    updates: {
      [meta.equipe.absolutePath()]: (atorPapel: EncaminhamentoInternoFormModel['responsavel']) => {
        if (atorPapel?.equipe?.id && tipoEstabelecimento !== TipoEstabelecimentoEnum.CEO) {
          return apollo
            .query({
              query: EquipeAtendimentoDocument,
              variables: { id: atorPapel.equipe.id },
            })
            .then((res) => res.data.equipe)
        } else {
          if (!atorPapel?.id) return null
        }
      },
    },
    isEqual: (a, b) => (!a && !b) || isEqual(a, b),
  },
]

const lotacaoCalculator = (meta: MetaRoot<EncaminhamentoInternoFormModel>, apollo: ApolloClient<object>) => [
  {
    field: meta.agendado.absolutePath(),
    updates: (value, _, allValues) => {
      const atendimento = resolveValue(allValues, meta)

      const tipoServicoSelecinadosSemOdonto = !!atendimento?.tiposServico
        ? atendimento?.tiposServico.filter((item) => item !== TipoServicoPadraoRecord.ODONTOLOGIA)
        : []

      if (value) {
        return apollo
          .query({
            query: AgendadoAtendimentoFormDocument,
            variables: { id: value },
          })
          .then((res) => {
            const updates = {
              [meta.responsavel.absolutePath()]: res.data.agendado.lotacaoAgendada,
            }
            const tiposServico = isCboCirurgiaoDentista(res.data.agendado.lotacaoAgendada.cbo.cbo2002)
              ? [TipoServicoPadraoRecord.ODONTOLOGIA, ...tipoServicoSelecinadosSemOdonto]
              : tipoServicoSelecinadosSemOdonto
            if (!isEqual(tiposServico, atendimento.tiposServico)) {
              updates[meta.tiposServico.absolutePath()] = tiposServico
            }
            return updates
          })
      } else {
        return {
          [meta.tiposServico.absolutePath()]:
            atendimento.responsavel && isCboCirurgiaoDentista(atendimento.responsavel.cbo.cbo2002)
              ? tipoServicoSelecinadosSemOdonto
              : atendimento?.tiposServico,
        }
      }
    },
  },
]

export const createNovoAtendimentoCalculations = (
  meta: MetaRoot<EncaminhamentoInternoFormModel>,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  apollo: ApolloClient<object>
): Calculation[] => [...lotacaoCalculator(meta, apollo), ...equipeCalculator(meta, tipoEstabelecimento, apollo)]
