import { Theme, useCss } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { parseDateFromLocalStorage } from 'components/form'
import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import {
  useAcompanhamentoPreNatalCardQuery,
  useAgendamentosDiaQuery,
  useAtendimentoIndividualViewQuery,
  useAtendimentoObservacaoViewQuery,
  useCiapCidAtendimentoQuery,
  useIdadeGestacionalQuery,
  useLoadAntecedentesQuery,
  useProblemasModalSimplificadoQuery,
  useProcedimentosAutomaticosQuery,
} from 'graphql/hooks.generated'
import {
  SituacaoAgendadoEnum,
  SituacaoProblema,
  TipoAtendimentoEnum,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { AtendimentoProvider } from 'hooks/atendimento-context/useAtendimentoContext'
import { useHeight } from 'hooks/useMeasure'
import { useLocalStorage } from 'hooks/useStorage'
import { partition } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'
import { tipoAtendimentoToTipoServicoMap } from 'types/enums'
import { isCboDentista } from 'types/NivelCbo'
import { isCboMedico } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'

import Permissions from '../../../types/Permissions'
import { AtendimentoVacinacaoState, initialValue as initialValueVacinacao } from '../atendimento-vacinacao/model'
import { grupoCboHistorico } from '../detail/acessos'
import { AgendamentosCidadaoAtendimentoView } from '../detail/agendamentos/AgendamentosCidadaoAtendimentoView'
import { atendimentoName } from '../detail/atendimentoName'
import { CidadaoDetailView } from '../detail/cadastro-cidadao/CidadaoDetailView'
import { FolhaRostoView } from '../detail/folha-rosto/FolhaRostoView'
import { AtendimentoHeader } from '../detail/header/AtendimentoHeader'
import { HistoricoAtendimentoView } from '../detail/historico/HistoricoAtendimentoView'
import { AntecedentesFormModel } from '../detail/soap/antecedentes/AntecedentesForm'
import { initialValueAntecedentes } from '../detail/soap/antecedentes/model'
import { getAltoRisco } from '../detail/soap/aside/pre-natal/util/AcompanhamentoPreNatalUtils'
import { AgendarConsultaSectionModel } from '../detail/soap/finalizacao/components/AgendarConsultaSection'
import { procedimentosUnicosOdontologia } from '../detail/soap/plano/evolucoes-odontologicas/util'
import { CIAP_W78_CODIGO, CID_Z34_CODIGO } from '../detail/soap/pre-natal/model'
import { SoapView } from '../detail/soap/SoapView'
import { VacinacaoView } from '../detail/vacinacao/VacinacaoView'
import { calculateProcedimentoAutomaticoToAdd } from '../utils/procedimentoAutomaticoUtils'
import { extractObservacaoEditableModules, initialValueObservacao } from './atendimento-observacao/util'
import { SoapState, TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO, TipoServicoModel } from './model'
import { getCiapCidPreNatal, getCidadaoAtendimento, getTiposAtendimento, initialValue } from './util'

interface AtendimentoIndividualViewProps {
  atendimentoId: ID
}

export function AtendimentoIndividualView(props: AtendimentoIndividualViewProps) {
  const { atendimentoId } = props
  const { data: sessao, hasAuthorization, isEstagio, tipoEstabelecimento } = useSession()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const match = useRouteMatch()
  const today = moment().startOf('day').toDate()
  const cbo2002 = acesso?.cbo.cbo2002
  const unidadeSaude = acesso?.unidadeSaude
  const { POLICLINICA_ENABLED } = useFlags()

  const [cacheState, setCacheState, deleteCacheState] = useLocalStorage<SoapState>(
    `${sessao.acesso.id}/atendimento/individual/${atendimentoId}`,
    true,
    undefined,
    cacheParser
  )

  const [initialStateLoaded, setInitialStateLoaded] = useState(!!cacheState)
  const [headerRef, headerHeight] = useHeight()

  const {
    data: { atendimento },
    loading: loadingAtendimento,
  } = useAtendimentoIndividualViewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      atendimentoId,
    },
  })

  const { agendado, cidadao, tiposServico, atendimentoProfissional, prontuario, registroTardio } = atendimento || {}
  const prontuarioId = prontuario?.id
  const {
    iniciadoEm: atendProfIniciadoEm,
    tipo: tipoAtendProf,
    atendimentoProfissionalObservacao,
    lotacao,
    tipoAtendimento,
    estagio,
  } = atendimentoProfissional ?? {}

  const {
    isAtendimentoOdonto,
    showAsAtendimentoProcedimentos,
    isAtendimentoAgendado,
    observacao: {
      isAtendimentoObservacao,
      isObservacaoAndResponsavel,
      isObservacaoAndAuxiliar,
      isObservacaoEmAndamento,
      responsavelObservacao,
    },
  } = getTiposAtendimento({
    atendimentoProfissional: {
      tipo: tipoAtendProf,
      lotacao,
      estagio,
      atendimentoProfissionalObservacao,
      finalizadoEm: atendimentoProfissional?.finalizadoEm,
    },
    isAtendimentoAgendado: !!atendimento?.agendado,
    statusRevisaoAtendimento: atendimento?.statusRevisaoAtendimento,
    isAcessoEstagio: isEstagio,
  })

  const {
    data: atendimentoObservacaoData,
    loading: loadingAtendimentoObservacaoQuery,
  } = useAtendimentoObservacaoViewQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isAtendimentoObservacao,
    variables: {
      atendimentoId,
    },
  })

  const {
    data: { procedimentosAutomaticos, registroAvaliacaoPuericultura },
    loading: loadingProcedimentosAutomaticos,
  } = useProcedimentosAutomaticosQuery({
    fetchPolicy: 'cache-first',
  })

  const {
    data: { antecedente },
    loading: loadingAntecedente,
  } = useLoadAntecedentesQuery({
    variables: {
      id: prontuarioId,
    },
    skip: !prontuarioId,
  })

  const {
    data: { ciaps, cids },
    loading: loadingCiapCid,
  } = useCiapCidAtendimentoQuery({
    fetchPolicy: 'cache-first',
    variables: {
      inputCiap: {
        query: CIAP_W78_CODIGO,
      },
      inputCid10: {
        query: CID_Z34_CODIGO,
      },
    },
  })

  const {
    loading: loadingProblemasCidadao,
    data: { problemas },
  } = useProblemasModalSimplificadoQuery({
    variables: { filtro: { prontuarioId } },
    skip: !prontuarioId,
  })

  const { loading: loadingAcompanhamentoPreNatalCard, data: dataPreNatalCard } = useAcompanhamentoPreNatalCardQuery({
    variables: { input: prontuarioId },
    skip: !prontuarioId,
  })
  const dataAtendimento = atendimentoProfissional?.iniciadoEm

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: { input: { prontuarioId, dataAtendimento: dateAsYyyyMmDd(dataAtendimento) } },
    skip: !prontuarioId || !dataAtendimento,
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  const { data: agendamentosDiaQueryResults, loading: loadingAgendamento } = useAgendamentosDiaQuery({
    variables: {
      input: {
        cidadaoId: cidadao?.id,
        dataAgendadoInicio: today,
        dataAgendadoFim: today,
        situacao: [SituacaoAgendadoEnum.AGENDADO, SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE],
        unidadeSaudeId: unidadeSaude.id,
        isForaDaUbs: false,
        isOrigemAtencaoDomiciliar: false,
      },
    },
    skip: !cidadao?.id,
  })

  const isGestante = !isEmpty(prontuario?.preNatalAtivo)
  const isPreNatalAltoRisco =
    !loadingAcompanhamentoPreNatalCard && getAltoRisco(dataPreNatalCard?.atendimentosProfUltimaGestacao)

  const ciapCidPreNatal = useMemo(() => getCiapCidPreNatal(ciaps?.content, cids?.content), [ciaps, cids])

  const cidadaoAtendimento = getCidadaoAtendimento(atendProfIniciadoEm, cidadao, isGestante, dataPreNatalCard)

  const nomeAtendimento = atendimentoName(tipoAtendProf, isObservacaoAndAuxiliar)
  const agendamentosDia = agendamentosDiaQueryResults?.agendados?.content
  const isMesmaLotacaoAgendamento = agendado?.lotacaoAgendada?.id === sessao.acesso.id
  const isMedico = isCboMedico(cbo2002)
  const isDentista = isCboDentista(cbo2002)

  const isLoading =
    loadingAtendimento ||
    loadingAgendamento ||
    loadingAntecedente ||
    loadingProcedimentosAutomaticos ||
    loadingCiapCid ||
    loadingProblemasCidadao ||
    loadingAcompanhamentoPreNatalCard ||
    loadingAtendimentoObservacaoQuery ||
    loadingIdadeGestacional

  const hasEncaminhamentoExterno =
    !isEmpty(cacheState?.plano?.encaminhamentoExterno?.encaminhamentosEspecializadosRecentes) ||
    !isEmpty(cacheState?.plano?.encaminhamentoExterno?.encaminhamentosRecentes)

  const qtdTotalProcedimentos =
    (cacheState?.plano?.intervencoesProcedimentos?.procedimentos?.length ?? 0) +
    (cacheState?.finalizacao?.procedimentosAdministrativos?.length ?? 0) +
    (procedimentosUnicosOdontologia(cacheState?.plano?.odontologia).length ?? 0)

  const concluiAgendamento =
    agendado &&
    (TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO.includes(cacheState?.finalizacao?.tipoAtendimento) ||
      (showAsAtendimentoProcedimentos && agendado.lotacaoAgendada.id === acesso?.id))

  useEffect(() => {
    const canCreateNovoAtendimento = hasAuthorization(Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir)

    if (!isLoading) {
      const agendadoSelecionado = cacheState?.finalizacao?.desfechoAtendimento?.atendimento?.agendado
      if (agendadoSelecionado && !agendamentosDia?.find((agendamentos) => agendamentos.id === agendadoSelecionado)) {
        cacheState.finalizacao.desfechoAtendimento.atendimento.agendado = undefined
      }

      if (!initialStateLoaded) {
        const initialProced = !isAtendimentoObservacao
          ? calculateProcedimentoAutomaticoToAdd(
              tipoAtendProf,
              isMedico,
              isDentista,
              tipoEstabelecimento,
              TipoAtendimentoEnum.CONSULTA_NO_DIA,
              POLICLINICA_ENABLED
            )
          : null

        const procedimentoAdministrativo = procedimentosAutomaticos?.find((proced) => proced.codigo === initialProced)

        const tiposServicoFiltrado = filterTiposServico(tiposServico, tipoAtendProf)
        const tipoAtendimentoInitialValue = getTipoAtendimentoInitialValue(
          showAsAtendimentoProcedimentos,
          isAtendimentoAgendado,
          isMesmaLotacaoAgendamento
        )

        const atendimentoInitialValues = initialValue({
          procedimentoAdministrativo,
          tipoAtendimento: tipoAtendimentoInitialValue,
          agendamentosDia,
          tiposServicoFiltrado,
          canCreateNovoAtendimento,
          isAtendimentoAgendado,
          isAtendimentoProcedimentos: showAsAtendimentoProcedimentos,
          isObservacaoAndResponsavel,
          isObservacaoAndAuxiliar,
          isRegistroTardio: registroTardio,
          idadeGestacional,
        })

        const atendimentoObservacaoInitialValues =
          isAtendimentoObservacao &&
          initialValueObservacao(
            atendimentoInitialValues,
            dataAtendimento,
            atendimentoProfissional?.tipo,
            extractObservacaoEditableModules(atendimentoObservacaoData?.atendimento?.atendimentoProfissional)
          )

        const vacinacaoInitialValues: AtendimentoVacinacaoState = initialValueVacinacao(
          prontuario?.puerpera,
          isGestante,
          tiposServico,
          canCreateNovoAtendimento,
          agendamentosDia
        )

        const antecedentesInitialValues: AntecedentesFormModel = initialValueAntecedentes(antecedente)

        !!atendimentoProfissional?.rascunho
          ? setCacheState(cacheParser(JSON.parse(atendimentoProfissional.rascunho) as SoapState))
          : setCacheState({
              ...atendimentoInitialValues,
              ...atendimentoObservacaoInitialValues,
              vacinacao: vacinacaoInitialValues,
              antecedentes: antecedentesInitialValues,
            })

        setInitialStateLoaded(true)
      }
    }
  }, [
    agendamentosDia,
    atendimento,
    cacheState,
    initialStateLoaded,
    isLoading,
    isMedico,
    procedimentosAutomaticos,
    prontuario,
    sessao.acesso.id,
    setCacheState,
    tiposServico,
    atendimentoProfissional,
    antecedente,
    isGestante,
    isAtendimentoOdonto,
    isMesmaLotacaoAgendamento,
    isObservacaoAndResponsavel,
    isAtendimentoObservacao,
    isObservacaoAndAuxiliar,
    isAtendimentoAgendado,
    registroTardio,
    hasAuthorization,
    tipoAtendProf,
    showAsAtendimentoProcedimentos,
    tipoAtendimento,
    atendimentoObservacaoData,
    tipoEstabelecimento,
    isDentista,
    dataIdadeGestacional,
    idadeGestacional,
    POLICLINICA_ENABLED,
    dataAtendimento,
  ])

  const [problemasResolvidos, problemasAtivosELatentes] = useMemo(
    () => partition(problemas?.content, (problema) => problema.situacao === SituacaoProblema.RESOLVIDO),
    [problemas]
  )

  if (isLoading || !initialStateLoaded) {
    return (
      <>
        {tipoAtendProf && <Breadcrumb title={`Atendimento ${nomeAtendimento}`} />}
        <PageLoading message='Carregando dados do atendimento individual...' />
      </>
    )
  }

  const renderFolhaRostoView = () => (
    <FolhaRostoView
      prontuarioId={prontuario.id}
      isGestante={isGestante}
      gestacoes={prontuario?.gestacoes || []}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
      dataReferencia={dataAtendimento}
      atendimentoId={atendimentoId}
      cidadao={cidadaoAtendimento}
      lotacao={atendimentoProfissional?.lotacao}
      tipoAtendimentoProfissional={tipoAtendProf}
      atendimentoProfissionalId={atendimentoProfissional?.id}
      isAtendimentoAgendado={isAtendimentoAgendado}
      isAtendimentoObservacao={isAtendimentoObservacao}
      isObservacaoAndResponsavel={isObservacaoAndResponsavel}
      idadeGestacional={idadeGestacional}
    />
  )

  const renderSoapView = () => (
    <SoapView
      atendimentoId={atendimentoId}
      prontuarioId={prontuarioId}
      cidadao={cidadaoAtendimento}
      dataAtendimento={dataAtendimento}
      procedimentosAutomaticos={procedimentosAutomaticos || []}
      isMedico={isMedico}
      isDentista={isDentista}
      agendamentoAtendimentoId={agendado?.id}
      concluiAgendamento={concluiAgendamento}
      hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      tiposServico={tiposServico}
      agendamentosDia={agendamentosDia}
      atendimentoProfissional={atendimentoProfissional}
      registroTardio={registroTardio}
      registroAvaliacaoPuericultura={registroAvaliacaoPuericultura}
      nomeAtendimento={nomeAtendimento}
      ciapCidPreNatal={ciapCidPreNatal}
      problemasAtivosELatentes={problemasAtivosELatentes}
      problemasResolvidos={problemasResolvidos}
      gestacoes={prontuario?.gestacoes || []}
      cacheState={cacheState}
      updateCache={setCacheState}
      clearCache={deleteCacheState}
      headerHeight={headerHeight}
      qtdTotalProcedimentos={qtdTotalProcedimentos}
    />
  )

  const renderAtendimentoHistoricoView = () => (
    <HistoricoAtendimentoView
      atendimentoId={atendimentoId}
      prontuarioId={prontuario.id}
      cidadao={cidadao}
      unidadeSaudeCnes={unidadeSaude.cnes}
      tipoAtendimentoProfissional={tipoAtendProf}
      registroTardio={registroTardio}
      clearCache={deleteCacheState}
      isAtendimentoObservacao={isAtendimentoObservacao}
      isObservacaoAndResponsavel={isObservacaoAndResponsavel}
    />
  )

  const renderCidadaoDetail = () => (
    <CidadaoDetailView
      atendimentoId={atendimentoId}
      cidadaoId={cidadao?.id}
      tipoAtendimentoProfissional={tipoAtendProf}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
      callbackUrl={match.url}
      isAtendimentoObservacao={isAtendimentoObservacao}
      isObservacaoAndResponsavel={isObservacaoAndResponsavel}
    />
  )

  const renderVacinacao = () => (
    <VacinacaoView
      atendimentoId={atendimentoId}
      cidadao={cidadaoAtendimento}
      gestante={isGestante}
      gestacoes={prontuario?.gestacoes || []}
      clearCache={deleteCacheState}
      dataAtendimento={atendimentoProfissional.iniciadoEm}
      prontuarioId={prontuario.id}
      agendamentosDia={agendamentosDia}
      cacheState={cacheState}
      updateCache={setCacheState}
      isAtendimentoVacinacao={false}
      tipoAtendimentoProfissional={tipoAtendProf}
      isAtendimentoObservacao={isAtendimentoObservacao}
      isObservacaoAndResponsavel={isObservacaoAndResponsavel}
      isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
      tipoEstabelecimento={tipoEstabelecimento}
    />
  )

  const renderAgendamentos = () => (
    <AgendamentosCidadaoAtendimentoView
      atendimentoId={atendimentoId}
      tipoAtendimentoProfissional={atendimentoProfissional.tipo}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
      isAtendimentoObservacao={isAtendimentoObservacao}
      isObservacaoAndResponsavel={isObservacaoAndResponsavel}
    />
  )

  const redirectTo = `${match.url}/${isObservacaoEmAndamento ? 'soap' : 'folha-rosto'}`

  const atendimentoProviderValue = {
    ...atendimento,
    cidadao: cidadaoAtendimento,
    observacao: {
      isAtendimentoObservacao,
      isObservacaoAndResponsavel,
      isObservacaoAndAuxiliar,
      isObservacaoEmAndamento,
      responsavelObservacao,
    },
    prontuario: {
      ...prontuario,
      ...(isGestante && {
        preNatalAtivo: {
          ...prontuario.preNatalAtivo,
          altoRisco: isPreNatalAltoRisco,
        },
      }),
    },
    tipoEstabelecimento: tipoEstabelecimento,
    statusRevisaoAtendimento: atendimento?.statusRevisaoAtendimento,
    tiposAtendimento: {
      isAtendimentoProcedimentos: showAsAtendimentoProcedimentos,
      isAtendimentoOdonto,
      isAtendimentoAgendado,
    },
  }

  return (
    <AtendimentoProvider input={atendimentoProviderValue}>
      {tipoAtendProf && <Breadcrumb title={`Atendimento ${nomeAtendimento}`} />}
      <AtendimentoHeader
        ref={headerRef}
        cidadaoId={cidadao?.id}
        cnsProfissional={atendimento?.atendimentoProfissional.lotacao.profissional.cns}
        cnsCidadao={cidadao?.cns}
        cnes={atendimento?.atendimentoProfissional.lotacao.unidadeSaude.cnes}
        municipioId={atendimento?.atendimentoProfissional.lotacao.municipio.id}
        dataAtendimento={atendimento?.atendimentoProfissional.iniciadoEm}
      />
      <PecSwitch>
        <Redirect exact path={match.url} to={redirectTo} />
        <Route path={`${match.url}/folha-rosto`} render={renderFolhaRostoView} />
        <PrivateRoute
          path={`${match.url}/historico`}
          render={renderAtendimentoHistoricoView}
          permission={grupoCboHistorico.visualizar}
        />
        <Route path={`${match.url}/soap`} render={renderSoapView} />
        <Route path={`${match.url}/cadastro-cidadao`} render={renderCidadaoDetail} />
        <Route path={`${match.url}/vacinacao`} render={renderVacinacao} />
        <Route path={`${match.path}/agendamentos/:cidadaoId`} render={renderAgendamentos} />
      </PecSwitch>
      {/* TODO (RNG)
       * Necessário ajustar o botão "Finalizar atendimento individual",
       * para utilizar o componente aqui.
       * Enquanto isso, é colocado em cada aba.
       * <AtendimentoFooter atendimentoId={atendimentoId} clearCache={deleteCacheState} />
       * */}
    </AtendimentoProvider>
  )
}

// copiado do bold pra memoizar o retorno, mas deve ser corrigido lá depois
export type StyleFactory<Classes extends string> = (
  theme: Theme,
  ...args: any[]
) => { readonly [key in Classes]: React.CSSProperties }

export type ClassNames<Classes extends string> = { readonly [key in Classes]: string }

const emptyStylesFactory: StyleFactory<any> = () => ({})

export const useLocalStyles = <Classes extends string>(
  factory: StyleFactory<Classes> = emptyStylesFactory,
  ...args: any[]
) => {
  const { css, theme } = useCss()

  return useMemo(() => {
    const map = factory(theme, ...args)

    const classes = Object.keys(map).reduce(
      (all, className) => ({
        ...all,
        [className]: css(map[className]),
      }),
      {} as ClassNames<Classes>
    )

    return { classes, css, theme }
  }, [args, css, factory, theme])
}

const cacheParser = (cacheState: SoapState): SoapState =>
  cacheState
    ? {
        ...cacheState,
        finalizacao: {
          ...cacheState.finalizacao,
          agendamentoConsulta: {
            ...cacheState.finalizacao?.agendamentoConsulta,
            ...parseHorarioAgendamento(cacheState.finalizacao?.agendamentoConsulta),
            proximasConsultas: {
              proximaConsultaPreNatal: parseHorarioAgendamento(
                cacheState.finalizacao?.agendamentoConsulta?.proximasConsultas?.proximaConsultaPreNatal
              ),
              proximaConsultaOdontologica: parseHorarioAgendamento(
                cacheState.finalizacao?.agendamentoConsulta?.proximasConsultas?.proximaConsultaOdontologica
              ),
            },
          },
        },
      }
    : undefined

const parseHorarioAgendamento = (agendamento: AgendarConsultaSectionModel) =>
  agendamento
    ? {
        ...agendamento,
        horario: parseDateFromLocalStorage(agendamento.horario),
      }
    : undefined

const filterTiposServico = (
  tiposServico: TipoServicoModel[],
  tipoAtendProf: TipoAtendimentoProfissional
): TipoServicoModel[] =>
  tiposServico?.filter((item) => !tipoAtendimentoToTipoServicoMap[tipoAtendProf]?.includes(item.id))

function getTipoAtendimentoInitialValue(
  isAtendimentoProcedimentos: boolean,
  isAgendado: boolean,
  isMesmaLotacaoAgendamento: boolean
) {
  if (!isAtendimentoProcedimentos) {
    if (!isAgendado) return TipoAtendimentoEnum.CONSULTA_NO_DIA
    else if (isMesmaLotacaoAgendamento) return TipoAtendimentoEnum.CONSULTA
  }

  return undefined
}
