/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon } from 'bold-ui'
import { EquipeSelectModel, FormRenderProps, SubmitButton } from 'components/form'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import { MicroareaSelectField } from 'components/form/field/select/MicroareaSelectField/MicroareaSelectField'
import { resolveName, usePecField } from 'components/form/final-form/hooks/useField'
import { Equipe, PageParams, UnidadeSaude } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'

import { UnidadeResponsavelLabel } from './UnidadeResponsavelLabel'

const meta = metaPath<AcompanhamentoCidadaosVinculadosFilterModel>()

export interface AcompanhamentoCidadaosVinculadosFilterModel {
  equipe?: EquipeSelectModel
  microareas?: string[]
  pageParams?: PageParams
}

interface AcompanhamentoCidadaosVinculadosFilterProps
  extends Pick<FormRenderProps<AcompanhamentoCidadaosVinculadosFilterModel>, 'handleSubmit'> {
  loading: boolean
  equipe?: Equipe
  unidadeSaude: UnidadeSaude
  hasAccessToTodasEquipes: boolean
  hasEquipeOrAccessToTodasEquipes: boolean
}

export function AcompanhamentoCidadaosVinculadosFilter(props: AcompanhamentoCidadaosVinculadosFilterProps) {
  const {
    unidadeSaude,
    equipe,
    loading,
    hasAccessToTodasEquipes,
    hasEquipeOrAccessToTodasEquipes,
    handleSubmit,
  } = props

  const {
    tools: { resetToInitialValue: resetEquipe },
  } = usePecField({ name: resolveName(meta.equipe) })

  const {
    tools: { resetToInitialValue: resetMicroareas },
  } = usePecField({ name: resolveName(meta.microareas) })

  const handleChangeEquipe = (equipe: EquipeSelectModel) => {
    if (!isUndefinedOrNull(equipe)) resetMicroareas()
  }

  const handleLimparFiltros = () => {
    resetMicroareas()
    resetEquipe()
  }

  return (
    <Grid>
      <Cell size={4}>
        <UnidadeResponsavelLabel nomeUnidadeSaude={unidadeSaude.nome} />
      </Cell>
      <Cell size={4}>
        <EquipeResponsavelField
          name={meta.equipe}
          equipe={equipe}
          unidadeSaudeId={unidadeSaude?.id}
          hasAccessToTodasEquipes={hasAccessToTodasEquipes}
          onChange={handleChangeEquipe}
          placeholder='Todas as equipes'
        />
      </Cell>
      <Cell size={4}>
        <MicroareaSelectField
          name={meta.microareas}
          label='Microárea'
          metaEquipe={meta.equipe}
          placeholder='Todas as microáreas'
          disabled={!hasEquipeOrAccessToTodasEquipes}
        />
      </Cell>
      <Cell size={12} alignSelf='flex-end'>
        <HFlow justifyContent='flex-end'>
          <Button onClick={handleLimparFiltros} disabled={!hasEquipeOrAccessToTodasEquipes}>
            Limpar filtros
          </Button>
          <SubmitButton handleSubmit={handleSubmit} loading={loading} disabled={!hasEquipeOrAccessToTodasEquipes}>
            <Icon icon='zoomOutline' style={styles.submitButton} />
            Buscar cidadãos
          </SubmitButton>
        </HFlow>
      </Cell>
    </Grid>
  )
}

const styles = {
  submitButton: css`
    margin-right: 0.5rem;
  `,
}
