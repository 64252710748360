import { SelectField } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  DoseImunobiologicoVacinacaoSelectFieldQuery,
  DoseImunobiologicoVacinacaoSelectFieldQueryVariables,
  SexoEnum,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type DoseImunobiologicoVacinacaoSelectFieldModel = DoseImunobiologicoVacinacaoSelectFieldQuery['doseImunobiologicoVacinacao']['content'][0]

interface DoseImunobiologicoVacinacaoSelectFieldProps
  extends AsyncSelectFieldProps<DoseImunobiologicoVacinacaoSelectFieldModel> {
  sexo?: SexoEnum
  imunobiologicoId?: ID
  estrategiaId?: ID
  isRegistroAnterior?: boolean
  semRegras?: boolean
  isOnlyRevacinacao?: boolean
  onlyCalendarioVacinal?: boolean
}

export function DoseImunobiologicoVacinacaoSelectField(props: DoseImunobiologicoVacinacaoSelectFieldProps) {
  const {
    sexo,
    imunobiologicoId,
    estrategiaId,
    isRegistroAnterior,
    semRegras,
    isOnlyRevacinacao,
    onlyCalendarioVacinal,
    ...rest
  } = props

  const { selectProps } = useAsyncQuerySelect<
    DoseImunobiologicoVacinacaoSelectFieldModel,
    DoseImunobiologicoVacinacaoSelectFieldQuery,
    DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >({
    query: DoseImunobiologicoVacinacaoSelectFieldDocument,
    extractItems: (data) => data?.doseImunobiologicoVacinacao?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        sexo,
        imunobiologicoId,
        estrategiaId,
        isRegistroAnterior,
        semRegras,
        isOnlyRevacinacao,
        onlyCalendarioVacinal,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
        },
      },
    }),
  })

  const itemToString = (item: DoseImunobiologicoVacinacaoSelectFieldModel) => item?.sigla

  return (
    <SelectField<DoseImunobiologicoVacinacaoSelectFieldModel> itemToString={itemToString} {...selectProps} {...rest} />
  )
}
