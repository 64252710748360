import { Button, Checkbox, HFlow, Icon, Tag, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { useBuscaDetailCidadaoLazyQuery } from 'graphql/hooks.generated'
import React, { CSSProperties, useEffect, useState } from 'react'
import { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'

import { CidadaoDadosModal } from './modals/CidadaoDadosModal'
import UnificacaoCidadaoCardInfo from './UnificacaoCidadaoCardInfo'

interface UnificacaoCadastroListItemProps {
  cidadao: CidadaoListingFormModel
  isSameItem(cidadaoId: ID): boolean
  selectCidadao(cidadao: CidadaoListingFormModel, checked: boolean): void
}

export const UnificacaoCadastroFoundListItem = (props: UnificacaoCadastroListItemProps) => {
  const { cidadao, isSameItem } = props
  const [checked, setChecked] = useState<boolean>(false)
  const [openModalCidadaoVisualizeCidadao, setOpenModalCidadaoVisualizeCidadao] = useState<boolean>(false)
  const { classes } = useStyles(createStyles)
  const [executeQuery, { data }] = useBuscaDetailCidadaoLazyQuery()

  useEffect(() => {
    const selected = isSameItem(cidadao.id)
    setChecked(selected)
  }, [isSameItem, cidadao.id])

  const handleChangeCidadao = () => {
    setChecked(!checked)
    props.selectCidadao(cidadao, !checked)
  }

  const handleClickVisualizeCidadao = (cidadaoId: ID) => {
    executeQuery({ variables: { id: cidadaoId } })
    setOpenModalCidadaoVisualizeCidadao(true)
  }

  return (
    <>
      {openModalCidadaoVisualizeCidadao && (
        <CidadaoDadosModal
          openModal={openModalCidadaoVisualizeCidadao}
          cidadao={data?.cidadao}
          closeModal={() => setOpenModalCidadaoVisualizeCidadao(false)}
        />
      )}
      {!checked && (
        <Box style={classes.list}>
          <HFlow style={{ justifyContent: 'space-between' }}>
            <HFlow hSpacing={1}>
              <Checkbox
                name='checkboxSelecionado'
                onChange={handleChangeCidadao}
                checked={checked}
                data-testid={'CardEncontrado' + cidadao.id + '.Checkbox'}
              />
              <VFlow vSpacing={0.25}>
                <HFlow hSpacing={0.5} alignItems='baseline' data-testid={'CardEncontrado' + cidadao.id + '.Nome'}>
                  {cidadao.nomeSocial ? (
                    <span>
                      <Text fontSize={1} fontWeight='bold'>
                        {cidadao.nomeSocial.titleCase()}
                      </Text>
                      {cidadao.nomeSocial && <Text fontWeight='bold'> (Nome social)</Text>}
                      {cidadao.nomeSocial && <Text>{' | ' + cidadao.nome.titleCase()}</Text>}
                    </span>
                  ) : (
                    <Text fontSize={1} fontWeight='bold'>
                      {cidadao.nome.titleCase()}
                    </Text>
                  )}
                  {!cidadao.ativo && <Tag>Inativo</Tag>}
                  {cidadao.faleceu && <Tag>Óbito</Tag>}
                  {cidadao.unificado && <Tag>Cadastro unificado</Tag>}
                  {cidadao.unificacaoBase && (
                    <Tooltip text={`Cadastro originado após unificação da Base ${cidadao.unificacaoBase}`}>
                      <Tag>Base {cidadao.unificacaoBase.titleCase()}</Tag>
                    </Tooltip>
                  )}
                </HFlow>
                <HFlow>
                  <UnificacaoCidadaoCardInfo cidadao={cidadao} testid='CardEncontrado' />
                </HFlow>
              </VFlow>
            </HFlow>
            <HFlow alignItems='center' hSpacing={0.5}>
              <Button
                kind='normal'
                skin='ghost'
                size='small'
                data-testid={'CardEncontrado' + cidadao.id + '.VisualizarButton'}
              >
                <Icon icon='zoomOutline' onClick={() => handleClickVisualizeCidadao(cidadao.id)} />
              </Button>
            </HFlow>
          </HFlow>
        </Box>
      )}
    </>
  )
}

const createStyles = (theme: Theme) => ({
  list: {
    textAlign: 'left',
    border: `1px solid ${theme.pallete.gray.c70}`,
  } as CSSProperties,
})
