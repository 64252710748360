import { DropdownItem, Text, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DropdownButton, DropdownLink } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import {
  useDesativarConfiguracaoAgendaOnlineLotacaoMutation,
  useExcluirConfiguracaoAgendaLotacaoMutation,
  useLotacaoAgendaActionsQuery,
  useProfissionaisLotacoesSectionQuery,
} from 'graphql/hooks.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { LotacaoAgendaModel } from 'view/agenda/model'
import {
  getInconsistenciasLotacaoAgendaOnline,
  InconsistenciasAgendaOnlineLotacaoModel,
} from 'view/profissional/detail/lotacao-section/LotacaoAgendaOnlineStatus'

interface AgendaConfiguracaoDropdownProps {
  lotacao: LotacaoAgendaModel
}

export function AgendaConfiguracaoDropdown({ lotacao }: AgendaConfiguracaoDropdownProps) {
  const { acesso, hasAuthorization } = useAcessoLotacaoOrEstagio()

  const { data: actionData } = useLotacaoAgendaActionsQuery({
    variables: {
      id: acesso?.id,
    },
    skip: !acesso?.id,
  })
  const { analytics } = useFirebase()

  const actions = actionData?.lotacao?.actions

  const alert = useAlert()
  const match = useRouteMatch()
  const handleRejection = useErrorHandler()

  const { data } = useProfissionaisLotacoesSectionQuery()
  const [excluirCfgAgendaMutation] = useExcluirConfiguracaoAgendaLotacaoMutation()
  const [desativarAgendaOnlineMutation] = useDesativarConfiguracaoAgendaOnlineLotacaoMutation()

  if (!lotacao)
    return (
      <Tooltip text='Nenhum profissional foi selecionado'>
        <DropdownButton icon='gearOutline' disabled></DropdownButton>
      </Tooltip>
    )

  const excluirAgendaSemConfirmacao = () => {
    return excluirCfgAgendaMutation({ variables: { id: lotacao.id } })
      .then(() => {
        alert('success', 'A agenda da lotação foi excluída com sucesso.')
        analytics.logEvent('excluir_agenda_AG')
      })
      .catch(handleRejection)
  }

  const handleExcluirAgenda = () => {
    confirm({
      title: 'Deseja excluir a agenda desta lotação?',
      body: renderConfirmBody(),
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: excluirAgendaSemConfirmacao,
    })()
  }

  const handleDesativarAgendaOnline = () => {
    confirm({
      title: 'Deseja excluir a agenda online desta lotação?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return desativarAgendaOnlineMutation({ variables: { id: lotacao.id } })
          .then(() => {
            alert('success', 'A agenda online da lotação foi excluída com sucesso.')
          })
          .catch(handleRejection)
      },
    })()
  }

  const renderConfirmBody = () => {
    if (lotacao.hasAgendamentoFuturo && lotacao.hasConfiguracaoAgendaOnline) {
      return (
        <>
          <Text component='p' style={{ marginBottom: '1rem' }}>
            Existem agendamentos futuros para esta lotação. Após a exclusão da configuração ainda será possível
            visualizá-los na agenda da lotação do profissional.
          </Text>
          <Text component='p'>A configuração de agenda online deste profissional também será excluída.</Text>
        </>
      )
    }

    if (lotacao.hasAgendamentoFuturo) {
      return 'Existem agendamentos futuros para esta lotação. Após a exclusão da configuração ainda será possível visualizá-los na agenda da lotação do profissional.'
    }
    if (lotacao.hasConfiguracaoAgendaOnline) {
      return 'A configuração de agenda online deste profissional também será excluída.'
    }
    return ''
  }

  const items: React.ReactNode[] = []
  const agendaOnlineAtiva = data?.conexao?.agendaOnline?.ativa
  const inconsistenciasLotacaoAgendaOnline =
    lotacao && getInconsistenciasLotacaoAgendaOnline(lotacao as InconsistenciasAgendaOnlineLotacaoModel)
  const lotacaoElegivelAgendaOnline = !inconsistenciasLotacaoAgendaOnline

  const permissaoConfigurarAgenda = actions?.permissaoConfigurarAgenda
  const permissaoConfigurarAgendaOnline = actions?.permissaoConfigurarAgendaOnline
  const permissaoFecharAgenda = actions?.permissaoFecharAgenda

  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgenda)) {
    items.push(
      <Tooltip
        key='editar'
        text={!lotacao.ativo ? 'Não é possível configurar agenda em lotação inativa' : permissaoConfigurarAgenda?.hint}
      >
        <DropdownLink
          to={`${match.url}/configuracaoAgenda`}
          disabled={!lotacao.ativo || !permissaoConfigurarAgenda?.enabled}
          onClick={() => {
            lotacao.hasConfiguracaoAgenda && analytics.logEvent('editar_config_agenda_AG')
          }}
        >
          {lotacao.hasConfiguracaoAgenda ? 'Editar configuração de agenda' : 'Criar configuração de agenda'}
        </DropdownLink>
      </Tooltip>
    )

    if (lotacao.hasConfiguracaoAgenda) {
      items.push(
        <Tooltip key='excluir' text={permissaoConfigurarAgenda?.hint}>
          <DropdownItem type='danger' onClick={handleExcluirAgenda} disabled={!permissaoConfigurarAgenda?.enabled}>
            Excluir agenda
          </DropdownItem>
        </Tooltip>
      )
    }
  }

  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgendaOnline)) {
    items.push(
      <Tooltip
        key='online'
        text={
          !agendaOnlineAtiva
            ? 'Município não ativou o serviço de Agenda Online e não está integrado com o Conecte SUS cidadão'
            : lotacaoElegivelAgendaOnline &&
              (inconsistenciasLotacaoAgendaOnline || permissaoConfigurarAgendaOnline?.hint)
        }
      >
        <DropdownLink
          to={`${match.url}/configuracaoAgendaOnline`}
          disabled={!agendaOnlineAtiva || !lotacaoElegivelAgendaOnline || !permissaoConfigurarAgendaOnline?.enabled}
          onClick={() => lotacao.hasConfiguracaoAgendaOnline && analytics.logEvent('editar_config_agenda_online_AG')}
        >
          {lotacao.hasConfiguracaoAgendaOnline
            ? 'Editar configuração de agenda online'
            : 'Criar configuração de agenda online'}
        </DropdownLink>
      </Tooltip>
    )

    if (lotacao.hasConfiguracaoAgendaOnline) {
      items.push(
        <Tooltip key='excluir-online' text={permissaoConfigurarAgendaOnline?.hint}>
          <DropdownItem
            type='danger'
            onClick={handleDesativarAgendaOnline}
            disabled={!permissaoConfigurarAgendaOnline?.enabled}
          >
            Excluir agenda online
          </DropdownItem>
        </Tooltip>
      )
    }
  }

  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.fecharAgenda)) {
    if (lotacao.ativo && lotacao.hasConfiguracaoAgenda) {
      items.push(
        <Tooltip key='fechamento' text={permissaoFecharAgenda?.hint}>
          <DropdownLink
            to={`/profissional/${lotacao.profissional.id}/dados/acessos/fechamento/${lotacao.id}`}
            disabled={!permissaoFecharAgenda?.enabled}
            onClick={() => {
              analytics.logEvent('editar_ver_fechamento_AG')
            }}
          >
            Visualizar fechamentos da agenda
          </DropdownLink>
        </Tooltip>
      )
    }
  }

  return (
    items.length > 0 && (
      <Tooltip text='Configurações'>
        <DropdownButton icon='gearOutline' disabled={items.length === 0}>
          {items}
        </DropdownButton>
      </Tooltip>
    )
  )
}
