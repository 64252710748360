import { Cell, Text, VFlow } from 'bold-ui'
import { MedicamentoCatmatSelectField } from 'components/form/field/select/MedicamentoCatMatSelectField'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { handleKeyEnter } from '../../../util'
import { MedicamentoFormModel } from '../model'

export interface MedicamentoSectionProps {
  path: MetaRoot<MedicamentoFormModel>
  values: MedicamentoFormModel
  onChange(): void
}

export default function MedicamentoSection({ onChange, path, values }: MedicamentoSectionProps) {
  return (
    <>
      <Cell size={6} data-testid='PrincipioAtivo'>
        <MedicamentoCatmatSelectField
          name={path.principioAtivoCombo}
          label='Princípio ativo / medicamento'
          onKeyUp={handleKeyEnter}
          required
          onChange={onChange}
          data-testid='PrincipioAtivoCombobox'
        />
      </Cell>
      <Cell size={6} data-testid='Concentracao'>
        <VFlow vSpacing={0.2}>
          <Text fontWeight='bold'>Concentração</Text>
          <Text data-testid='ConcentracaoLabel'>{values?.principioAtivoCombo?.medicamento?.concentracao ?? '-'} </Text>
        </VFlow>
      </Cell>
    </>
  )
}
