/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Text, Tooltip } from 'bold-ui'
import { NumberField, RadioField } from 'components/form'
import { TipoFrequencia, TurnoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { MetaRoot } from 'util/metaPath'

import { handleKeyEnter } from '../../../util'
import { MedicamentoFormModel } from '../model'
import CheckButtonField from './CheckButtonField'
import { PeriodoTratamentoSelectField } from './PeriodoTratamentoSelectField'

export interface TipoFrequenciaContentProps {
  tipoFrequencia: TipoFrequencia
  path: MetaRoot<MedicamentoFormModel>
  doseUnica: boolean
}

export default function TipoFrequenciaContent({ tipoFrequencia, path, doseUnica }: TipoFrequenciaContentProps) {
  return useMemo(
    () => (
      <Tooltip text={doseUnica && 'Medicamento definido como dose única'}>
        <span>
          {tipoFrequencia === TipoFrequencia.INTERVALO && (
            <Grid gap={1}>
              <Cell
                style={css`
                  padding-top: 0.9rem;
                `}
              >
                <Text fontWeight='bold'>A cada</Text>
              </Cell>
              <Cell>
                <HFlow hSpacing={0.5}>
                  <CheckButtonField
                    name={path.intervaloDose}
                    originalValue={4}
                    disabled={doseUnica}
                    dataTestId='4hCheckButton'
                  >
                    4h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={6} disabled={doseUnica}>
                    6h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={8} disabled={doseUnica}>
                    8h
                  </CheckButtonField>
                  <CheckButtonField name={path.intervaloDose} originalValue={12} disabled={doseUnica}>
                    12h
                  </CheckButtonField>
                  <CheckButtonField
                    name={path.intervaloDose}
                    originalValue={24}
                    disabled={doseUnica}
                    dataTestId='24hCheckButton'
                  >
                    24h
                  </CheckButtonField>
                </HFlow>
              </Cell>
              <Cell size={2}>
                <NumberField
                  name={path.intervaloDose}
                  maxLength={2}
                  disabled={doseUnica}
                  data-testid='IntervaloTextField'
                />
              </Cell>
              <Cell
                style={css`
                  padding-top: 0.9rem;
                `}
              >
                <Text fontWeight='bold'>horas</Text>
              </Cell>
            </Grid>
          )}
          {tipoFrequencia === TipoFrequencia.FREQUENCIA && (
            <Grid gap={0}>
              <Cell size={5}>
                <HFlow hSpacing={0.5}>
                  <CheckButtonField
                    name={path.frequenciaDose}
                    originalValue={1}
                    disabled={doseUnica}
                    dataTestId='1xCheckButton'
                  >
                    1x
                  </CheckButtonField>
                  <CheckButtonField
                    name={path.frequenciaDose}
                    originalValue={2}
                    disabled={doseUnica}
                    dataTestId='2xCheckButton'
                  >
                    2x
                  </CheckButtonField>
                  <CheckButtonField name={path.frequenciaDose} originalValue={3} disabled={doseUnica}>
                    3x
                  </CheckButtonField>
                  <CheckButtonField name={path.frequenciaDose} originalValue={4} disabled={doseUnica}>
                    4x
                  </CheckButtonField>
                  <NumberField
                    name={path.frequenciaDose}
                    maxLength={2}
                    disabled={doseUnica}
                    data-testid='FrequenciaDoseTextField'
                  />
                </HFlow>
              </Cell>
              <Cell size={7}>
                <Grid gap={0.5}>
                  <Cell
                    size={4}
                    style={css`
                      padding-top: 1rem;
                      text-align: center;
                    `}
                  >
                    <Text fontWeight='bold'>vez(es) a cada</Text>
                  </Cell>
                  <Cell size={3}>
                    <NumberField
                      name={path.quantidadePeriodoFrequenciaTurno}
                      maxLength={2}
                      disabled={doseUnica}
                      data-testid='QuantidadePeriodoFrequenciaTextField'
                    />
                  </Cell>
                  <Cell size={5}>
                    <PeriodoTratamentoSelectField
                      name={path.unidadeMedidaTempoFrequenciaTurno}
                      onKeyUp={handleKeyEnter}
                      disabled={doseUnica}
                      data-testid='UnidadeMedidaTempoFrequencia'
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          )}
          {tipoFrequencia === TipoFrequencia.TURNO && (
            <Grid gap={0.5}>
              <Cell
                size={5}
                style={css`
                  padding-top: 0.8rem;
                `}
              >
                <HFlow justifyContent='space-evenly'>
                  <RadioField
                    name={path.turno}
                    value={TurnoEnum.MANHA}
                    label='Manhã'
                    disabled={doseUnica}
                    data-testid='TurnoManhaRadio'
                  />
                  <RadioField
                    name={path.turno}
                    value={TurnoEnum.TARDE}
                    label='Tarde'
                    disabled={doseUnica}
                    data-testid='TurnoTardeRadio'
                  />
                  <RadioField name={path.turno} value={TurnoEnum.NOITE} label='Noite' disabled={doseUnica} />
                </HFlow>
              </Cell>
              <Cell size={7}>
                <Grid gap={0.5}>
                  <Cell
                    size={2}
                    style={css`
                      text-align: end;
                      padding-top: 1rem;
                    `}
                  >
                    <Text fontWeight='bold'>a cada</Text>
                  </Cell>
                  <Cell size={3}>
                    <NumberField
                      name={path.quantidadePeriodoFrequenciaTurno}
                      maxLength={2}
                      disabled={doseUnica}
                      data-testid='TurnoTextField'
                    />
                  </Cell>
                  <Cell size={5}>
                    <PeriodoTratamentoSelectField
                      name={path.unidadeMedidaTempoFrequenciaTurno}
                      onKeyUp={handleKeyEnter}
                      disabled={doseUnica}
                      data-testid='UnidadeMedidaTempoTurno'
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          )}
        </span>
      </Tooltip>
    ),
    [
      doseUnica,
      path.frequenciaDose,
      path.intervaloDose,
      path.quantidadePeriodoFrequenciaTurno,
      path.turno,
      path.unidadeMedidaTempoFrequenciaTurno,
      tipoFrequencia,
    ]
  )
}
